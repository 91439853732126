// IntroSection.js

import React, { useState, useEffect } from 'react';
import './IntroSection.css';

const IntroSection = () => {
  const [quote, setQuote] = useState('');

  useEffect(() => {
    const quotes = [
      "We drive data literacy and information exchange for curious minds.",
      "Your life is what you make of it. Just go.",
      "I want to forge my own path ahead even if it is slow going.",
      "Unlock 15% Revenue Growth with DataJinx.",
      "Drive business outcomes and see an average quarterly ROI of 2,178%."
    ];
    setQuote(quotes[Math.floor(Math.random() * quotes.length)]);
  }, []);

  const isDarkMode = document.body.classList.contains('dark-mode');

  return (
    <section className={`intro ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className="hero-image"></div>
      <div className="intro-content">
        <h1>Jinx is an AI webcrawler that finds and connects to any online data source</h1>
        <h3>Interface with your data through an integrated LLM</h3>
        <p>{quote}</p>
        <button className="cta-button">Book a Demo</button>
      </div>
    </section>
  );
};

export default IntroSection;
