// src/components/billing/Billing.js

import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { db } from '../../config/firebase';
import { doc, collection, query, onSnapshot } from 'firebase/firestore';
import Payment from './Payment';
import AddCardDetails from './AddCardDetails';
import Balance from './Balance';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { STRIPE_PUBLISHABLE_KEY } from '../../config/stripe';
import './Billing.css'; // Add a CSS file for styles

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const Billing = () => {
  const { user } = useAuth();
  const [fundedAmount, setFundedAmount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [showAddCard, setShowAddCard] = useState(false);
  const [storedCards, setStoredCards] = useState([]);

  useEffect(() => {
    if (user) {
      const billingRef = doc(db, 'users', user.uid, 'billing', 'default');
      const cardsQuery = query(collection(billingRef, 'cards'));
      const unsubscribeCards = onSnapshot(cardsQuery, (snapshot) => {
        const cards = [];
        snapshot.forEach((doc) => {
          const data = doc.data();
          cards.push({ id: doc.id, ...data });
        });
        setStoredCards(cards);
      });

      const paymentsQuery = query(collection(billingRef, 'payments'));
      const unsubscribePayments = onSnapshot(paymentsQuery, (snapshot) => {
        let totalAmount = 0;
        snapshot.forEach((doc) => {
          const data = doc.data();
          totalAmount += data.amount;
        });
        setFundedAmount(totalAmount);
      });

      return () => {
        unsubscribeCards();
        unsubscribePayments();
      };
    }
  }, [user]);

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleAddCardClick = () => {
    setShowAddCard(true);
  };

  const handleCloseAddCard = () => {
    setShowAddCard(false);
  };

  return (
    <div className={`billing-container ${showAddCard ? 'swipe-left' : ''}`}>
      {!showAddCard ? (
        <div className="billing-content">
          <h1>Billing</h1>
          <div className="funded-amount">
            <p>Total Funded Amount: ${fundedAmount / 100}</p> {/* Convert cents to dollars */}
            <Balance /> {/* Show the balance component */}
          </div>
          <div className="funding-section">
            <input
              type="number"
              value={amount}
              onChange={handleAmountChange}
              placeholder="Enter amount to fund"
              className="amount-input"
            />
            <Payment amount={amount} storedCards={storedCards} />
          </div>
          <div className="card-section">
            <button onClick={handleAddCardClick} className="add-card-button">Add Card Details</button>
          </div>
        </div>
      ) : (
        <div className="add-card-container">
          <Elements stripe={stripePromise}>
            <AddCardDetails onClose={handleCloseAddCard} />
          </Elements>
        </div>
      )}
    </div>
  );
};

export default Billing;
