// src/services/logService.js

import { db } from '../config/firebase';
import { collection, addDoc, Timestamp } from 'firebase/firestore';

export const logChange = async (userId, message) => {
  try {
    await addDoc(collection(db, 'notifications'), {
      userId,
      message,
      timestamp: Timestamp.now()
    });
  } catch (e) {
    console.error('Error adding document: ', e);
  }
};
