// src/components/Profile.js

import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore, doc, getDoc, updateDoc, collection, query, where, onSnapshot } from 'firebase/firestore';
import { app } from '../config/firebase'; // Import the initialized Firebase app
import './Profile.css'; // Ensure this points to the correct path of your CSS file
import { logChange } from '../services/logService'; // Import the log service

const auth = getAuth(app); // Get the auth instance using the initialized app
const db = getFirestore(app); // Get the Firestore instance using the initialized app

const Profile = () => {
  const [user, setUser] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const isDarkMode = document.body.classList.contains('dark-mode');
  const [userData, setUserData] = useState({
    email: '',
    firstName: '',
    lastName: '',
    gender: '',
    userType: 'individual',
    address: '',
    suburb: '',
    state: '',
    postcode: '',
    country: '',
    orgName: '',
    position: '',
    isOrgAdmin: false,
  });
  const [userTeams, setUserTeams] = useState([]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        setUser(currentUser);
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          setUserData(userDoc.data());
          if (userDoc.data().orgId) {
            const teamsQuery = query(collection(db, 'organizations', userDoc.data().orgId, 'teams'), where('members', 'array-contains', currentUser.uid));
            const unsubscribeTeams = onSnapshot(teamsQuery, (snapshot) => {
              const teams = snapshot.docs.map(doc => doc.data().name);
              setUserTeams(teams);
            });
            return () => unsubscribeTeams();
          }
        }
      } else {
        setUser(null);
      }
    });

    return () => unsubscribe();
  }, []);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUserData({
      ...userData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const handleSave = async () => {
    if (user) {
      try {
        await updateDoc(doc(db, 'users', user.uid), userData);
        alert('Profile updated successfully!');
        await logChange(user.uid, 'Profile updated'); // Log the change
        setEditMode(false);
      } catch (error) {
        console.error('Error updating profile: ', error);
        alert('Error updating profile.');
      }
    }
  };

  const renderAddressFields = () => (
    <div className="addressGroup">
      <label>Address:</label>
      <input type="text" name="address" value={userData.address} onChange={handleChange} disabled={!editMode} />

      <label>Suburb:</label>
      <input type="text" name="suburb" value={userData.suburb} onChange={handleChange} disabled={!editMode} />

      <label>State:</label>
      <input type="text" name="state" value={userData.state} onChange={handleChange} disabled={!editMode} />

      <label>Postcode:</label>
      <input type="text" name="postcode" value={userData.postcode} onChange={handleChange} disabled={!editMode} />

      <label>Country:</label>
      <input type="text" name="country" value={userData.country} onChange={handleChange} disabled={!editMode} />
    </div>
  );

  const renderCompanyFields = () => (
    <>
      <label>Company Name:</label>
      <input type="text" name="orgName" value={userData.orgName} readOnly />

      <label>Position:</label>
      <select name="position" value={userData.position} onChange={handleChange} disabled={!editMode}>
        <option value="">Select Position</option>
        <option value="director">Director</option>
        <option value="manager">Manager</option>
        <option value="employee">Employee</option>
        <option value="other">Other</option>
      </select>

      <label>
        <input type="checkbox" name="isOrgAdmin" checked={userData.isOrgAdmin} onChange={handleChange} disabled={!editMode} />
        Is Organization Admin
      </label>
    </>
  );

  return (
    <div className={`profileContainer ${isDarkMode ? 'dark-mode' : ''}`}>
      <div className={`mainContainer ${isDarkMode ? 'dark-mode' : ''}`}>
        <div className="profileHeader">
          <h2>Profile</h2>
        </div>
        <form>
          <label>Email/Username:</label>
          <input type="email" value={userData.email} readOnly />
  
          <label>First Name:</label>
          <input type="text" name="firstName" value={userData.firstName} onChange={handleChange} disabled={!editMode} />
  
          <label>Last Name:</label>
          <input type="text" name="lastName" value={userData.lastName} onChange={handleChange} disabled={!editMode} />
  
          <label>Gender:</label>
          <div className="radio-group">
            <label>
              <input type="radio" value="Male" name="gender" onChange={handleChange} checked={userData.gender === 'Male'} disabled={!editMode} /> Male
            </label>
            <label>
              <input type="radio" value="Female" name="gender" onChange={handleChange} checked={userData.gender === 'Female'} disabled={!editMode} /> Female
            </label>
            <label>
              <input type="radio" value="Other" name="gender" onChange={handleChange} checked={userData.gender === 'Other'} disabled={!editMode} /> Other
            </label>
            <label>
              <input type="radio" value="Prefer not to say" name="gender" onChange={handleChange} checked={userData.gender === 'Prefer not to say'} disabled={!editMode} /> Prefer not to say
            </label>
          </div>
  
          <label>User Type:</label>
          <div className="radio-group">
            <label>
              <input type="radio" value="individual" name="userType" onChange={handleChange} checked={userData.userType === 'individual'} disabled={!editMode} /> Individual
            </label>
            <label>
              <input type="radio" value="business" name="userType" onChange={handleChange} checked={userData.userType === 'business'} disabled={!editMode} /> Business
            </label>
          </div>
  
          {userData.userType === 'individual' ? renderAddressFields() : renderCompanyFields()}
  
          {userData.userType === 'business' && (
            <>
              <label>Teams:</label>
              <ul>
                {userTeams.map((team, index) => (
                  <li key={index}>{team}</li>
                ))}
              </ul>
            </>
          )}
  
          {editMode ? (
            <button type="button" onClick={handleSave}>Save</button>
          ) : (
            <button className="editButton" onClick={() => setEditMode(true)}>✎</button>
          )}
        </form>
      </div>
    </div>
  );
};

export default Profile;
