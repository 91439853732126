// src/components/projects/Projects.js
import React, { useState, useEffect } from 'react';
import { collection, query, where, onSnapshot, addDoc, deleteDoc, doc, getDoc, getDocs, updateDoc } from 'firebase/firestore';
import { db, auth } from '../../config/firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { handleCreateProjectSubmit, handleEditProjectSubmit } from './ProjectForm'; // Updated this import
import { useAuth } from '../../hooks/useAuth';
import JobList from './JobList';
import './Projects.css';
import './Jobs.css';
import './Tasks.css';
import { debounce } from 'lodash';
import { logChange } from '../../services/logService';
import { predefinedJobs, STATUS } from './constants';
import ProjectList from './ProjectList';
import ProjectForm from './ProjectForm';
import JobForm from './JobForm';
import JobFilters from './JobFilters';
import '../../styles/global.css';


const Projects = () => {
  const { user } = useAuth();
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState();
  const [filterStatus, setFilterStatus] = useState(STATUS.ACTIVE);
  const [filteredJobs, setFilteredJobs] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const [expandedJobId, setExpandedJobId] = useState([]);
  const [isCreateProjectPopupOpen, setIsCreateProjectPopupOpen] = useState(false);
  const [isEditProjectPopupOpen, setIsEditProjectPopupOpen] = useState(false);
  const [editedProject, setEditedProject] = useState(null);
  const [sortOption, setSortOption] = useState('dateCreated');
  const [isDescriptionExpanded, setIsDescriptionExpanded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [showCreateFirstProject, setShowCreateFirstProject] = useState(false);
  const [isCreatingProject, setIsCreatingProject] = useState(false);
  const [basicTags, setBasicTags] = useState([]);
  const [advancedTags, setAdvancedTags] = useState([]);
  const [jobSearchTerm, setJobSearchTerm] = useState('');
  const isDarkMode = document.body.classList.contains('dark-mode');
  const [newProject, setNewProject] = useState({
    name: '',
    description: '',
    tags: '',
    industry: '',
    maxCredits: '',
    teamMembers: [],
    priority: 1,
    starred: false,
  });
  const [showSortOptions, setShowSortOptions] = useState(false);
  const [selectedJob, setSelectedJob] = useState('');
  const [isAddJobPopupOpen, setIsAddJobPopupOpen] = useState(false);
  const [jobConfig, setJobConfig] = useState({});
  const [isCreateJobEnabled, setIsCreateJobEnabled] = useState(false);
  const [jobStatus, setJobStatus] = useState({});
  const [jobType, setJobType] = useState('Basic');

  const handleJobTypeChange = (e) => {
    setJobType(e.target.value);
  };

  const handleDeleteBasicTag = (i) => {
    setBasicTags(basicTags.filter((tag, index) => index !== i));
  };

  const handleAdditionBasicTag = (tag) => {
    setBasicTags([...basicTags, tag]);
  };

  const handleDeleteAdvancedTag = (i) => {
    setAdvancedTags(advancedTags.filter((tag, index) => index !== i));
  };

  const handleAdditionAdvancedTag = (tag) => {
    setAdvancedTags([...advancedTags, tag]);
  };

  const toggleDescription = () => {
    setIsDescriptionExpanded(!isDescriptionExpanded);
  };

  const openEditProjectPopup = (project) => {
    setEditedProject({ ...project });
    setIsEditProjectPopupOpen(true);
  };

  const closeEditProjectPopup = () => {
    setIsEditProjectPopupOpen(false);
    setEditedProject(null);
  };

  const handleEditProjectChange = (e) => {
    const { name, value } = e.target;
    setEditedProject((prevProject) => ({
      ...prevProject,
      [name]: value,
    }));
  };

  const openCreateProjectPopup = () => {
    setIsCreatingProject(true);
    setIsCreateProjectPopupOpen(true);
    setIsCreatingProject(false);
  };

  const closeCreateProjectPopup = () => {
    setIsCreateProjectPopupOpen(false);
    setNewProject({
      name: '',
      description: '',
      tags: '',
      industry: '',
      maxCredits: '',
      teamMembers: [],
      priority: 1,
      starred: false,
    });
  };

  const handleCreateProjectChange = (e) => {
    const { name, value } = e.target;
    setNewProject((prevProject) => ({
      ...prevProject,
      [name]: value,
    }));
  };

  useEffect(() => {
    if (user) {
      setIsLoading(true);
      const projectsRef = collection(db, 'projects');
      const q = query(projectsRef, where('userId', '==', user.uid));

      const unsubscribe = onSnapshot(q, (snapshot) => {
        const projectsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        })).sort((a, b) => b.createdAt - a.createdAt);

        setProjects(projectsData);
        if (projectsData.length === 0) {
          setSelectedProject(null);
        } else if (!selectedProject || !projectsData.some(project => project.id === selectedProject.id)) {
          setSelectedProject(projectsData[0]);
        }
        setFilterStatus(STATUS.ACTIVE);
        setIsLoading(false);
      });

      return () => unsubscribe();
    }
  }, [user, selectedProject]);

  const filteredProjects = searchTerm
    ? projects.filter((project) =>
      (project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.tags.toLowerCase().includes(searchTerm.toLowerCase())) &&
      project.status !== STATUS.DELETED
    )
    : projects.filter((project) => project.status !== STATUS.DELETED);

  useEffect(() => {
    if (selectedProject) {
      const fetchJobStatus = async () => {
        const jobsRef = collection(db, 'projects', selectedProject.id, 'currentJobs');
        const jobSnapshot = await getDocs(jobsRef);
        const status = {};
        jobSnapshot.forEach(doc => {
          status[doc.data().title] = doc.data().status;
        });
        setJobStatus(status);
      };

      fetchJobStatus();

      setIsLoading(true);
      const currentJobsRef = collection(db, 'projects', selectedProject.id, 'currentJobs');
      const pausedJobsRef = collection(db, 'projects', selectedProject.id, 'pausedJobs');
      const deletedJobsRef = collection(db, 'projects', selectedProject.id, 'deletedJobs');

      const applySortAndFilter = (jobsData) => {
        if (sortOption === 'alphabetical') {
          jobsData.sort((a, b) => a.title.localeCompare(b.title));
        } else if (sortOption === 'dateCreated') {
          jobsData.sort((a, b) => b.createdAt - a.createdAt);
        }
        return jobsData.filter((job) =>
          job.title.toLowerCase().includes(jobSearchTerm.toLowerCase())
        );
      };

      const unsubscribeCurrentJobs = onSnapshot(currentJobsRef, (snapshot) => {
        let currentJobsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        currentJobsData = applySortAndFilter(currentJobsData);

        setFilteredJobs((prevJobs) => {
          const updatedJobs = prevJobs.filter((job) => job.status !== STATUS.ACTIVE);
          updatedJobs.push({ status: STATUS.ACTIVE, jobs: currentJobsData });
          return updatedJobs;
        });
      });

      const unsubscribePausedJobs = onSnapshot(pausedJobsRef, (snapshot) => {
        let pausedJobsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        pausedJobsData = applySortAndFilter(pausedJobsData);

        setFilteredJobs((prevJobs) => {
          const updatedJobs = prevJobs.filter((job) => job.status !== STATUS.PAUSED);
          updatedJobs.push({ status: STATUS.PAUSED, jobs: pausedJobsData });
          return updatedJobs;
        });
      });

      const unsubscribeDeletedJobs = onSnapshot(deletedJobsRef, (snapshot) => {
        let deletedJobsData = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        deletedJobsData = applySortAndFilter(deletedJobsData);

        setFilteredJobs((prevJobs) => {
          const updatedJobs = prevJobs.filter((job) => job.status !== STATUS.DELETED);
          updatedJobs.push({ status: STATUS.DELETED, jobs: deletedJobsData });
          return updatedJobs;
        });
      });

      setIsLoading(false);

      return () => {
        unsubscribeCurrentJobs();
        unsubscribePausedJobs();
        unsubscribeDeletedJobs();
      };
    }
  }, [selectedProject, jobSearchTerm, sortOption, filterStatus]);

  const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

  const toggleAccordion = (jobId) => {
    setExpandedJobId((prevExpandedJobIds) =>
      prevExpandedJobIds.includes(jobId)
        ? prevExpandedJobIds.filter((id) => id !== jobId)
        : [...prevExpandedJobIds, jobId]
    );
  };

  const handleDeleteProject = async (projectId) => {
    if (window.confirm('Are you sure you want to delete this project and all its jobs?')) {
      if (isRequestPending) return;
      setIsRequestPending(true);
      setIsLoading(true);
      try {
        const projectRef = doc(db, 'projects', projectId);
        await updatePausedJobsAndTasks(projectId);
        await updateCurrentJobsAndTasks(projectId);
        await updateDoc(projectRef, { status: STATUS.DELETED });

        const remainingProjects = projects.filter((project) => project.id !== projectId);
        setProjects(remainingProjects);
        setSelectedProject(remainingProjects.length > 0 ? remainingProjects[0] : null);

        await logChange(user.uid, `Deleted project ${selectedProject.name}`);
      } catch (error) {
        console.error("Error deleting project: ", error);
      } finally {
        setIsLoading(false);
        setIsRequestPending(false);
      }
    }
  };

  const updatePausedJobsAndTasks = async (projectId) => {
    const pausedJobsRef = collection(db, 'projects', projectId, 'pausedJobs');
    const pausedJobsSnapshot = await getDocs(pausedJobsRef);

    const updatePromises = pausedJobsSnapshot.docs.map(async (jobDoc) => {
      const jobData = jobDoc.data();
      if (jobData.status !== STATUS.DELETED) { // Ensure the job isn't already deleted
        const updatedTasks = jobData.tasks.map((task) => ({
          ...task,
          status: 'cancelled',
        }));

        const updatedJobData = {
          ...jobData,
          status: STATUS.DELETED,
          tasks: updatedTasks,
          isExpanded: false,
          userId: user.uid,
        };

        await addDoc(collection(db, 'projects', projectId, 'deletedJobs'), updatedJobData);
        await deleteDoc(jobDoc.ref);
      }
    });

    await Promise.all(updatePromises);
  };

  const updateCurrentJobsAndTasks = async (projectId) => {
    const currentJobsRef = collection(db, 'projects', projectId, 'currentJobs');
    const currentJobsSnapshot = await getDocs(currentJobsRef);

    const updatePromises = currentJobsSnapshot.docs.map(async (jobDoc) => {
      const jobData = jobDoc.data();
      if (jobData.status !== STATUS.DELETED) { // Ensure the job isn't already deleted
        const updatedTasks = jobData.tasks.map((task) => ({
          ...task,
          status: 'cancelled',
        }));

        const updatedJobData = {
          ...jobData,
          status: STATUS.DELETED,
          tasks: updatedTasks,
          isExpanded: false,
          userId: user.uid,
        };

        await addDoc(collection(db, 'projects', projectId, 'deletedJobs'), updatedJobData);
        await deleteDoc(jobDoc.ref);
      }
    });

    await Promise.all(updatePromises);
  };

  const handleDeleteJob = async (projectId, jobId, jobStatus) => {
    if (window.confirm('Are you sure you want to delete this job?')) {
      if (isRequestPending) return;
      setIsRequestPending(true);
      setIsLoading(true);
      const jobRef = doc(db, 'projects', projectId, jobStatus === STATUS.PAUSED ? 'pausedJobs' : 'currentJobs', jobId);

      try {
        const jobSnapshot = await getDoc(jobRef);
        if (jobSnapshot.exists()) {
          const jobData = jobSnapshot.data();
          const updatedTasks = jobData.tasks.map((task) => ({
            ...task,
            status: 'cancelled',
          }));

          const updatedJobData = {
            ...jobData,
            status: STATUS.DELETED,
            tasks: updatedTasks,
            isExpanded: false,
            userId: user.uid,
          };

          await addDoc(collection(db, 'projects', projectId, 'deletedJobs'), updatedJobData);
          await deleteDoc(jobRef);

          // Delete dependent jobs
          await deleteDependentJobs(projectId, jobData.title);

          setFilteredJobs((prevJobs) =>
            prevJobs.map((jobCategory) => {
              if (jobCategory.status === jobStatus) {
                return {
                  ...jobCategory,
                  jobs: jobCategory.jobs.filter((job) => job.id !== jobId),
                };
              }
              return jobCategory;
            })
          );

          // Update job status state to refresh the add job popup
          setJobStatus(prevStatus => ({
            ...prevStatus,
            [jobData.title]: STATUS.DELETED,
          }));

          await logChange(user.uid, `Deleted job ${jobData.title} in project ${selectedProject.name}`);
        }
      } catch (error) {
        console.error("Error deleting job: ", error);
      } finally {
        setIsLoading(false);
        setIsRequestPending(false);
      }
    }
  };

  const deleteDependentJobs = async (projectId, jobName) => {
    const currentJobsRef = collection(db, 'projects', projectId, 'currentJobs');
    const pausedJobsRef = collection(db, 'projects', projectId, 'pausedJobs');
    const deletedJobsRef = collection(db, 'projects', projectId, 'deletedJobs');

    const jobSnapshot = await getDocs(query(currentJobsRef, where('dependency', '==', jobName)));
    for (const docSnapshot of jobSnapshot.docs) {
      const jobData = docSnapshot.data();

      // Check if job already exists in deletedJobs
      const deletedJobDoc = await getDoc(doc(db, 'projects', projectId, 'deletedJobs', docSnapshot.id));
      if (deletedJobDoc.exists()) {
        continue; // Skip if the job is already deleted
      }

      const updatedTasks = jobData.tasks.map((task) => ({
        ...task,
        status: 'cancelled',
      }));

      const updatedJobData = {
        ...jobData,
        status: STATUS.DELETED,
        tasks: updatedTasks,
        isExpanded: false,
        userId: user.uid,
      };

      await addDoc(deletedJobsRef, updatedJobData);
      await deleteDoc(docSnapshot.ref);

      // Recursive call to delete further dependent jobs
      await deleteDependentJobs(projectId, jobData.title);
    }

    const pausedJobSnapshot = await getDocs(query(pausedJobsRef, where('dependency', '==', jobName)));
    for (const docSnapshot of pausedJobSnapshot.docs) {
      const jobData = docSnapshot.data();

      // Check if job already exists in deletedJobs
      const deletedJobDoc = await getDoc(doc(db, 'projects', projectId, 'deletedJobs', docSnapshot.id));
      if (deletedJobDoc.exists()) {
        continue; // Skip if the job is already deleted
      }

      const updatedTasks = jobData.tasks.map((task) => ({
        ...task,
        status: 'cancelled',
      }));

      const updatedJobData = {
        ...jobData,
        status: STATUS.DELETED,
        tasks: updatedTasks,
        isExpanded: false,
        userId: user.uid,
      };

      await addDoc(deletedJobsRef, updatedJobData);
      await deleteDoc(docSnapshot.ref);

      // Recursive call to delete further dependent jobs
      await deleteDependentJobs(projectId, jobData.title);
    }

    // Special case for "Clean and Consolidate Collected Data"
    if (jobName === 'Clean and Consolidate Collected Data') {
      const specialJobs = ['Create Insights Report from Prepared Data', 'Train AI Model on Prepared Data', 'Access Cleaned Data'];
      const specialJobSnapshot = await getDocs(query(currentJobsRef, where('title', 'in', specialJobs)));
      for (const docSnapshot of specialJobSnapshot.docs) {
        const jobData = docSnapshot.data();

        // Check if job already exists in deletedJobs
        const deletedJobDoc = await getDoc(doc(db, 'projects', projectId, 'deletedJobs', docSnapshot.id));
        if (deletedJobDoc.exists()) {
          continue; // Skip if the job is already deleted
        }

        const updatedTasks = jobData.tasks.map((task) => ({
          ...task,
          status: 'cancelled',
        }));

        const updatedJobData = {
          ...jobData,
          status: STATUS.DELETED,
          tasks: updatedTasks,
          isExpanded: false,
          userId: user.uid,
        };

        await addDoc(deletedJobsRef, updatedJobData);
        await deleteDoc(docSnapshot.ref);

        // Recursive call to delete further dependent jobs
        await deleteDependentJobs(projectId, jobData.title);
      }
    }
  };

  const handlePauseJob = async (projectId, jobId, jobStatus) => {
    if (window.confirm('Are you sure you want to pause this job?')) {
      if (isRequestPending) return;
      setIsRequestPending(true);
      setIsLoading(true);
      const jobRef = doc(db, 'projects', projectId, 'currentJobs', jobId);

      try {
        const jobSnapshot = await getDoc(jobRef);
        if (jobSnapshot.exists()) {
          const jobData = jobSnapshot.data();
          const updatedTasks = jobData.tasks.map((task) => ({
            ...task,
            status: STATUS.PAUSED,
          }));

          const updatedJobData = {
            ...jobData,
            status: STATUS.PAUSED,
            tasks: updatedTasks,
            isExpanded: false,
            userId: user.uid,
          };

          await addDoc(collection(db, 'projects', projectId, 'pausedJobs'), updatedJobData);
          await deleteDoc(jobRef);

          // Pause dependent jobs
          await pauseDependentJobs(projectId, jobData.title);

          setFilteredJobs((prevJobs) =>
            prevJobs.map((jobCategory) => {
              if (jobCategory.status === STATUS.ACTIVE) {
                return {
                  ...jobCategory,
                  jobs: jobCategory.jobs.filter((job) => job.id !== jobId),
                };
              }
              return jobCategory;
            })
          );

          // Update job status state to refresh the add job popup
          setJobStatus(prevStatus => ({
            ...prevStatus,
            [jobData.title]: STATUS.PAUSED,
          }));

          await logChange(user.uid, `Paused job ${jobData.title} in project ${selectedProject.name}`);
        }
      } catch (error) {
        console.error("Error pausing job: ", error);
      } finally {
        setIsLoading(false);
        setIsRequestPending(false);
      }
    }
  };

  const pauseDependentJobs = async (projectId, jobName) => {
    const currentJobsRef = collection(db, 'projects', projectId, 'currentJobs');
    const pausedJobsRef = collection(db, 'projects', projectId, 'pausedJobs');

    const jobSnapshot = await getDocs(query(currentJobsRef, where('dependency', '==', jobName)));
    for (const docSnapshot of jobSnapshot.docs) {
      const jobData = docSnapshot.data();

      // Check if job already exists in pausedJobs
      const pausedJobDoc = await getDoc(doc(db, 'projects', projectId, 'pausedJobs', docSnapshot.id));
      if (pausedJobDoc.exists()) {
        continue; // Skip if the job is already paused
      }

      const updatedTasks = jobData.tasks.map((task) => ({
        ...task,
        status: STATUS.PAUSED,
      }));

      const updatedJobData = {
        ...jobData,
        status: STATUS.PAUSED,
        tasks: updatedTasks,
        isExpanded: false,
        userId: user.uid,
      };

      await addDoc(pausedJobsRef, updatedJobData);
      await deleteDoc(docSnapshot.ref);

      // Recursive call to pause further dependent jobs
      await pauseDependentJobs(projectId, jobData.title);
    }

    // Special case for "Clean and Consolidate Collected Data"
    if (jobName === 'Clean and Consolidate Collected Data') {
      const specialJobs = ['Create Insights Report from Prepared Data', 'Train AI Model on Prepared Data', 'Access Cleaned Data'];
      const specialJobSnapshot = await getDocs(query(currentJobsRef, where('title', 'in', specialJobs)));
      for (const docSnapshot of specialJobSnapshot.docs) {
        const jobData = docSnapshot.data();

        // Check if job already exists in pausedJobs
        const pausedJobDoc = await getDoc(doc(db, 'projects', projectId, 'pausedJobs', docSnapshot.id));
        if (pausedJobDoc.exists()) {
          continue; // Skip if the job is already paused
        }

        const updatedTasks = jobData.tasks.map((task) => ({
          ...task,
          status: STATUS.PAUSED,
        }));

        const updatedJobData = {
          ...jobData,
          status: STATUS.PAUSED,
          tasks: updatedTasks,
          isExpanded: false,
          userId: user.uid,
        };

        await addDoc(pausedJobsRef, updatedJobData);
        await deleteDoc(docSnapshot.ref);

        // Recursive call to pause further dependent jobs
        await pauseDependentJobs(projectId, jobData.title);
      }
    }
  };

  const handlePlayJob = async (projectId, jobId, jobName) => {
    if (window.confirm('Are you sure you want to resume this job?')) {
      if (isRequestPending) return;
      setIsRequestPending(true);
      setIsLoading(true);
      const jobRef = doc(db, 'projects', projectId, 'pausedJobs', jobId);

      try {
        const jobSnapshot = await getDoc(jobRef);
        if (jobSnapshot.exists()) {
          const jobData = jobSnapshot.data();
          const dependency = jobData.dependency;  // Retrieve the dependency from jobData

          // Only proceed if dependency is defined
          if (dependency) {
            // Check if the parent job is active in both currentJobs and pausedJobs
            const parentJobCurrentRef = query(collection(db, 'projects', projectId, 'currentJobs'), where('title', '==', dependency));
            const parentJobPausedRef = query(collection(db, 'projects', projectId, 'pausedJobs'), where('title', '==', dependency));

            const parentJobCurrentSnapshot = await getDocs(parentJobCurrentRef);
            const parentJobPausedSnapshot = await getDocs(parentJobPausedRef);

            const parentJobActive = parentJobCurrentSnapshot.docs.some(doc => doc.data().status === STATUS.ACTIVE);
            const parentJobPaused = parentJobPausedSnapshot.docs.some(doc => doc.data().status === STATUS.PAUSED);

            if (!parentJobActive || parentJobPaused) {
              alert(`Parent job: ${dependency} must be in active state to queue this job`);
              setIsLoading(false);
              setIsRequestPending(false);
              return;
            }
          }

          const updatedTasks = jobData.tasks.map((task, index) => ({
            ...task,
            status: index === 0 ? STATUS.ACTIVE : 'pending',
          }));

          const updatedJobData = {
            ...jobData,
            status: STATUS.ACTIVE,
            tasks: updatedTasks,
            isExpanded: false,
            userId: user.uid,
          };

          await addDoc(collection(db, 'projects', projectId, 'currentJobs'), updatedJobData);
          await deleteDoc(jobRef);

          setFilteredJobs((prevJobs) =>
            prevJobs.map((jobCategory) => {
              if (jobCategory.status === STATUS.PAUSED) {
                return {
                  ...jobCategory,
                  jobs: jobCategory.jobs.filter((job) => job.id !== jobId),
                };
              }
              return jobCategory;
            })
          );

          // Update job status state to refresh the add job popup
          setJobStatus(prevStatus => ({
            ...prevStatus,
            [jobName]: STATUS.ACTIVE,
          }));

          await logChange(user.uid, `Resumed job ${jobName} in project ${selectedProject.name}`);
        }
      } catch (error) {
        console.error("Error resuming job: ", error);
      } finally {
        setIsLoading(false);
        setIsRequestPending(false);
      }
    }
  };

  const debouncedSearchTerm = debounce((term) => {
    setSearchTerm(term);
  }, 300);

  const handleJobConfigChange = (e) => {
    const { name, value } = e.target;
    setJobConfig((prevConfig) => ({
      ...prevConfig,
      [name]: value,
    }));
    checkCreateJobEnabled(selectedJob);
    console.log('Job Config Updated:', jobConfig);
  };

  const checkCreateJobEnabled = (selectedJob) => {
    const job = predefinedJobs.find((job) => job.id === selectedJob);
    if (job) {
      const requiredFields = jobType === 'Basic'
        ? ['dropdownField', 'radioSelection']
        : ['advancedDropdownField', 'advancedRadioSelection'];
      const allFieldsFilled = requiredFields.every(field => jobConfig[field]);
      setIsCreateJobEnabled(allFieldsFilled);
      console.log('All mandatory fields filled:', allFieldsFilled);
    }
  };

  useEffect(() => {
    checkCreateJobEnabled(selectedJob);
  }, [jobConfig, selectedJob]);

  const isJobSelectable = (jobId) => {
    const job = predefinedJobs.find(job => job.id === jobId);
    const jobStatusValue = jobStatus[job.title];

    // If the job is already active or paused, it should not be selectable
    if (jobStatusValue === STATUS.ACTIVE || jobStatusValue === STATUS.PAUSED) {
      return false;
    }

    const jobIndex = predefinedJobs.findIndex(job => job.id === jobId);
    if (jobIndex === 0) return true; // The first job is always selectable

    const priorJob = predefinedJobs[jobIndex - 1];
    const priorJobStatus = jobStatus[priorJob.title];

    if (priorJobStatus === STATUS.ACTIVE || priorJobStatus === 'active') {
      return true;
    }

    // Special case for 'Clean and Consolidate Collected Data'
    if (jobId === 'job4') {
      const dataCollectionJob = predefinedJobs.find(job => job.id === 'job2');
      const dataCollectionJobCompleted = jobStatus[dataCollectionJob.title] === 'active';
      return dataCollectionJobCompleted;
    }

    // Allow access to 5, 6, and 7 if 'Clean and Consolidate Collected Data' is completed
    if (['job5', 'job6', 'job7'].includes(jobId)) {
      const cleanDataJob = predefinedJobs.find(job => job.id === 'job4');
      const cleanDataJobCompleted = jobStatus[cleanDataJob.title] === 'active';
      return cleanDataJobCompleted;
    }

    return false;
  };

  const handleJobStatusChange = async (projectId, jobId, newStatus) => {
    const jobRef = doc(db, 'projects', projectId, 'jobs', jobId);
    await updateDoc(jobRef, { status: newStatus });

    if (newStatus === 'paused' || newStatus === 'deleted') {
      const dependentJobs = predefinedJobs.filter(job => {
        if (job.id === 'job4') return false; // Clean and Consolidate Collected Data is a special case
        const jobIndex = predefinedJobs.findIndex(j => j.id === job.id);
        const priorJob = predefinedJobs[jobIndex - 1];
        return priorJob?.id === jobId || (newStatus === 'paused' && jobId === 'job4' && job.id !== 'job4');
      });

      for (const job of dependentJobs) {
        const depJobRef = collection(db, 'projects', projectId, 'jobs');
        const depJobSnapshot = await getDocs(query(depJobRef, where('title', '==', job.title)));
        depJobSnapshot.forEach(async (doc) => {
          await updateDoc(doc.ref, { status: newStatus });
        });
      }
    }
  };

  const openAddJobPopup = async () => {
    setJobConfig({});
    setSelectedJob('');
    setIsCreateJobEnabled(false);

    // Fetch the latest job statuses
    const fetchJobStatus = async () => {
      if (selectedProject) {
        const jobsRef = collection(db, 'projects', selectedProject.id, 'currentJobs');
        const pausedJobsRef = collection(db, 'projects', selectedProject.id, 'pausedJobs');

        const jobSnapshot = await getDocs(jobsRef);
        const pausedJobSnapshot = await getDocs(pausedJobsRef);
        const status = {};

        jobSnapshot.forEach(doc => {
          status[doc.data().title] = doc.data().status;
        });

        pausedJobSnapshot.forEach(doc => {
          status[doc.data().title] = doc.data().status;
        });

        setJobStatus(status);
      }
    };

    await fetchJobStatus();
    setIsAddJobPopupOpen(true);
  };

  const handleCreateJob = async (projectId, jobId) => {
    if (isRequestPending) return;
    setIsRequestPending(true);
    setIsLoading(true);
    const selectedJob = predefinedJobs.find((job) => job.id === jobId);
    if (selectedJob) {
      const tasks = selectedJob.tasks.map((task, index) => ({
        name: task,
        status: index === 0 ? STATUS.ACTIVE : 'pending',
        userId: user.uid,
      }));
      const jobStatus = STATUS.ACTIVE;

      // Determine dependency using job name
      const jobIndex = predefinedJobs.findIndex(job => job.id === jobId);
      let dependency = null;
      if (selectedJob.title === 'Clean and Consolidate Collected Data') {
        const dataCollectionJob = predefinedJobs.find(job => job.title === 'Start Data Collection');
        dependency = dataCollectionJob.title;
      } else if (['Create Insights Report from Prepared Data', 'Train AI Model on Prepared Data', 'Access Cleaned Data'].includes(selectedJob.title)) {
        const cleanDataJob = predefinedJobs.find(job => job.title === 'Clean and Consolidate Collected Data');
        dependency = cleanDataJob.title;
      } else if (jobIndex > 0) {
        dependency = predefinedJobs[jobIndex - 1].title;
      }

      const jobConfigData = {
        ...jobConfig,
        tags: jobType === 'Basic' ? basicTags : advancedTags,
      };

      const jobData = {
        title: selectedJob.title,
        jobName: selectedJob.title,
        status: jobStatus,
        description: '',
        tasks,
        createdAt: new Date().getTime(),
        userId: user.uid,
        isExpanded: false,
        dependency,
        config: jobConfigData,
      };

      console.log('Creating job with data:', jobData);

      try {
        const docRef = await addDoc(collection(db, 'projects', projectId, 'currentJobs'), jobData);

        console.log('Document created with ID:', docRef.id);

        // Update jobStatus state to refresh the add job popup
        setJobStatus(prevStatus => ({
          ...prevStatus,
          [selectedJob.title]: jobStatus,
        }));

        setFilterStatus(STATUS.ACTIVE);
        setExpandedJobId((prevExpandedJobIds) => [...prevExpandedJobIds, docRef.id]);

        await logChange(user.uid, `Created job ${selectedJob.title} in project ${selectedProject.name}`);

        closeAddJobPopup(); // Close the add job popup
      } catch (error) {
        console.error("Error creating job:", error);
      } finally {
        setIsLoading(false);
        setIsRequestPending(false);
      }
    }
  };

  // Additional debugging for checking selected job and create job enabled state
  useEffect(() => {
    console.log('Selected Job:', selectedJob);
    console.log('Is Create Job Enabled:', isCreateJobEnabled);
  }, [selectedJob, isCreateJobEnabled]);

  const closeAddJobPopup = () => {
    setIsAddJobPopupOpen(false);
  };

  return (
    <div className={`project ${isLoading || isCreatingProject ? 'translucent' : ''}`}>
      {(isLoading || isCreatingProject) && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      )}
      <div className={`project-container ${isSidebarOpen ? '' : 'sidebar-collapsed'}`}>
        <ProjectList
          projects={projects}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          openCreateProjectPopup={openCreateProjectPopup}
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          isSidebarOpen={isSidebarOpen}
          setIsSidebarOpen={setIsSidebarOpen}  // Pass the setIsSidebarOpen function
        />
        <main className={`project-details ${isDarkMode ? 'dark-mode' : ''}`}>
          {showCreateFirstProject && !selectedProject && (
            <div className="create-first-project">
              <h2>Create your first project</h2>
              <p>To create a project, click on the 'Create New Project' button above.</p>
              <p>You can add tasks, assign team members, and set priorities.</p>
              <button onClick={openCreateProjectPopup}>Create New Project</button>
            </div>
          )}
          {!showCreateFirstProject && selectedProject && selectedProject.status !== STATUS.DELETED && (
            <>
              <div className="project-header">
                <div className="project-title-container">
                  <h3>{selectedProject.name}</h3>
                  <p className={`project-description ${isDescriptionExpanded ? 'expanded' : ''}`}>
                    {selectedProject.description}
                  </p>
                  {selectedProject.description.length > 100 && (
                    <button className="expand-btn" onClick={toggleDescription}>
                      {isDescriptionExpanded ? 'Show less' : 'Show more'}
                    </button>
                  )}
                </div>
                <div className="project-buttons">
                  <button className="edit-project-btn" onClick={() => openEditProjectPopup(selectedProject)}>
                    <span className="pen-icon">&#9998;</span>
                  </button>
                  <button className="delete-project-btn" onClick={() => handleDeleteProject(selectedProject.id)}>
                    &times;
                  </button>
                </div>
              </div>
              <div className="job-status-container">
                <button
                  className={filterStatus === 'all' ? 'active' : ''}
                  onClick={() => setFilterStatus('all')}
                >
                  All Jobs
                </button>
                <button
                  className={filterStatus === STATUS.ACTIVE ? 'active' : ''}
                  onClick={() => setFilterStatus(STATUS.ACTIVE)}
                >
                  Active Jobs
                </button>
                <button
                  className={filterStatus === 'completed' ? 'active' : ''}
                  onClick={() => setFilterStatus('completed')}
                >
                  Completed Jobs
                </button>
                <button
                  className={filterStatus === STATUS.PAUSED ? 'active' : ''}
                  onClick={() => setFilterStatus(STATUS.PAUSED)}
                >
                  Paused Jobs
                </button>
                <button
                  className={filterStatus === STATUS.DELETED ? 'active' : ''}
                  onClick={() => setFilterStatus(STATUS.DELETED)}
                >
                  Deleted Jobs
                </button>
              </div>
              <div className="job-options-container">
                <JobFilters
                  setShowSortOptions={setShowSortOptions}
                  showSortOptions={showSortOptions}
                  setSortOption={setSortOption}
                  sortOption={sortOption}
                  setJobSearchTerm={setJobSearchTerm}
                  openAddJobPopup={openAddJobPopup}
                />
              </div>
              <JobList
                jobs={filterStatus === 'all' ? filteredJobs : filteredJobs.filter((jobCategory) => jobCategory.status === filterStatus)}
                toggleAccordion={toggleAccordion}
                handleDeleteJob={handleDeleteJob}
                handlePauseJob={handlePauseJob}
                handlePlayJob={(projectId, jobId, jobData) => handlePlayJob(projectId, jobId, jobData.title)}
                selectedProject={selectedProject}
                predefinedJobs={predefinedJobs}
                handleCreateJob={handleCreateJob}
                expandedJobId={expandedJobId}
              />
            </>
          )}
        </main>
      </div>
      {isCreateProjectPopupOpen && (
        <ProjectForm
          project={newProject}
          onSubmit={(e) => handleCreateProjectSubmit(e, newProject, user, projects, setProjects, setSelectedProject, setFilterStatus, closeCreateProjectPopup, setIsLoading, setIsRequestPending, setShowCreateFirstProject)}
          onChange={handleCreateProjectChange}
          onClose={closeCreateProjectPopup}
          isCreate={true}
        />
      )}
      {isEditProjectPopupOpen && (
        <ProjectForm
          project={editedProject}
          onSubmit={(e) => handleEditProjectSubmit(e, editedProject, setIsRequestPending, setIsLoading, setProjects, user, logChange, closeEditProjectPopup, setSelectedProject, isRequestPending)}
          onChange={handleEditProjectChange}
          onClose={closeEditProjectPopup}
          isCreate={false}
        />
      )}
      {isAddJobPopupOpen && (
        <JobForm
          jobType={jobType}
          handleJobTypeChange={handleJobTypeChange}
          selectedJob={selectedJob}
          setSelectedJob={setSelectedJob}
          handleJobConfigChange={handleJobConfigChange}
          basicTags={basicTags}
          advancedTags={advancedTags}
          handleDeleteBasicTag={handleDeleteBasicTag}
          handleAdditionBasicTag={handleAdditionBasicTag}
          handleDeleteAdvancedTag={handleDeleteAdvancedTag}
          handleAdditionAdvancedTag={handleAdditionAdvancedTag}
          handleCreateJob={handleCreateJob}
          isCreateJobEnabled={isCreateJobEnabled}
          closeAddJobPopup={closeAddJobPopup}
          selectedProject={selectedProject}
          predefinedJobs={predefinedJobs}
          isJobSelectable={isJobSelectable}
        />
      )}
    </div>
  );
};

export default Projects;
