// src/components/Footer.js

import React from 'react';
import './Footer.css';

function Footer() {
  const handlePrivacy = () => {
    // Implement the logic for handling the privacy link click
    // For example, you can redirect the user to the privacy page
  };

  const handleTerms = () => {
    // Implement the logic for handling the terms link click
    // For example, you can redirect the user to the terms page
  };

  return (
    <div className="footer">
      <button className="link-button" onClick={handlePrivacy}>Privacy</button>
      <button className="link-button" onClick={handleTerms}>Terms</button>
      DataJinx © 2024
    </div>
  );
}

export default Footer;
