import React from 'react';
import { AiOutlineFilter } from 'react-icons/ai';

const JobFilters = ({ setShowSortOptions, showSortOptions, setSortOption, sortOption, setJobSearchTerm, openAddJobPopup }) => {
  return (
    <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
      <AiOutlineFilter onClick={() => setShowSortOptions(!showSortOptions)} className="filter-icon" />
      {showSortOptions && (
        <div className="sort-popup">
          <label>
            <input
              type="radio"
              value="dateCreated"
              checked={sortOption === 'dateCreated'}
              onChange={() => setSortOption('dateCreated')}
            />
            Date Created
          </label>
          <label>
            <input
              type="radio"
              value="alphabetical"
              checked={sortOption === 'alphabetical'}
              onChange={() => setSortOption('alphabetical')}
            />
            Alphabetical
          </label>
        </div>
      )}
      <input
        type="text"
        placeholder="Search jobs..."
        onChange={(e) => setJobSearchTerm(e.target.value)}
        style={{ width: '200px', marginLeft: '10px' }}
      />
      <button onClick={openAddJobPopup} style={{ marginLeft: '10px' }}>Add Job</button>
    </div>
  );
};

export default JobFilters;
