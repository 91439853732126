import React, { useEffect, useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { db } from '../../config/firebase';
import { doc, collection, query, onSnapshot } from 'firebase/firestore';

const Balance = () => {
  const { user } = useAuth();
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    if (user) {
      const billingRef = doc(db, 'users', user.uid, 'billing', 'default');
      const paymentsQuery = query(collection(billingRef, 'payments'));

      const unsubscribe = onSnapshot(paymentsQuery, (snapshot) => {
        let totalAmount = 0;
        snapshot.forEach((doc) => {
          const data = doc.data();
          totalAmount += data.amount;
        });
        setBalance(totalAmount);
      });

      return () => unsubscribe();
    }
  }, [user]);

  return (
    <div>
      <br></br>
      <p>Total Balance: ${balance / 100}</p> {/* Convert cents to dollars */}
    </div>
  );
};

export default Balance;
