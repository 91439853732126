// src/components/Teams.js

import React, { useState, useEffect, useRef } from 'react';
import { collection, query, where, onSnapshot, addDoc, updateDoc, getDocs, doc, writeBatch, arrayUnion, deleteDoc, setDoc, getDoc, orderBy } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../hooks/useAuth';
import './Teams.css';
import { Link } from 'react-router-dom';
import { logChange } from '../services/logService';
import Balance from './billing/Balance';



const Teams = () => {
  const { user } = useAuth();
  const [orgName, setOrgName] = useState('');
  const [teams, setTeams] = useState([]);
  const [newTeam, setNewTeam] = useState('');
  const [invitations, setInvitations] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]);
  const [chatOpen, setChatOpen] = useState(true); // Initially open, but minimized
  const [currentChat, setCurrentChat] = useState('Organization Chat');
  const [chatMessages, setChatMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [userOrg, setUserOrg] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [credits, setCredits] = useState(0);
  const [chatMinimized, setChatMinimized] = useState(true); // Start minimized
  const [usersColors, setUsersColors] = useState({});
  const [userColor, setUserColor] = useState('#000000'); // Default color black
  // Fetch user's color on mount
useEffect(() => {
  const fetchUserColor = async () => {
    if (user) {
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      if (userDoc.exists) {
        setUserColor(userDoc.data().color || '#000000');
      }
    }
  };
  fetchUserColor();
}, [user]);

  const [chatRooms, setChatRooms] = useState([]);
  const chatBodyRef = useRef(null);
  
  
  const scrollToBottom = () => {
    if (chatBodyRef.current) {
      chatBodyRef.current.scrollTop = chatBodyRef.current.scrollHeight;
    }
  };
  useEffect(() => {
    scrollToBottom();
  }, [chatMessages]);
  useEffect(() => {
    if (chatOpen && !chatMinimized) {
      scrollToBottom();
    }
  }, [chatOpen, chatMinimized]);
  const [expandedTeams, setExpandedTeams] = useState([]); // Add this state

  const toggleTeamExpansion = (teamId) => {
    setExpandedTeams(prevExpandedTeams =>
      prevExpandedTeams.includes(teamId)
        ? prevExpandedTeams.filter(id => id !== teamId)
        : [...prevExpandedTeams, teamId]
    );
  };

  useEffect(() => {
    if (user) {
      const q = query(collection(db, 'users', user.uid, 'pendingInvites'));
      const unsubscribe = onSnapshot(q, async (snapshot) => {
        const invites = await Promise.all(snapshot.docs.map(async (docSnapshot) => {
          const data = docSnapshot.data();
          const teamDoc = await getDoc(doc(db, 'organizations', data.orgId, 'teams', data.teamId));
          const teamData = teamDoc.data();
          return { id: docSnapshot.id, ...data, teamName: teamData ? teamData.name : 'Unknown Team' };
        }));
        setInvitations(invites);
      });

      return () => unsubscribe();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const orgQuery = query(collection(db, 'organizations'), where('admin', '==', user.uid));
      const unsubscribeOrg = onSnapshot(orgQuery, (snapshot) => {
        const orgs = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        if (orgs.length > 0) {
          setUserOrg(orgs[0]);
          setOrgName(orgs[0].name);
          setIsAdmin(true);
          setCredits(orgs[0].credits || 0);
        }
      });

      const userDocRef = doc(db, 'users', user.uid);
      const unsubscribeUser = onSnapshot(userDocRef, (docSnapshot) => {
        const userData = docSnapshot.data();
        if (userData && userData.orgId && !isAdmin) {
          const orgDocRef = doc(db, 'organizations', userData.orgId);
          getDoc(orgDocRef).then((orgDoc) => {
            const orgData = orgDoc.data();
            setUserOrg({ id: orgDoc.id, name: orgData.name });
            setCredits(orgData.credits || 0);
          });
        }
      });

      return () => {
        unsubscribeOrg();
        unsubscribeUser();
      };
    }
  }, [user, isAdmin]);

  const assignCredits = async (amount, recipientId) => {
    if (amount > credits) {
      alert('Not enough credits available.');
      return;
    }

    const batch = writeBatch(db);
    const recipientRef = doc(db, 'users', recipientId);
    const orgRef = doc(db, 'organizations', userOrg.id);

    batch.update(recipientRef, {
      credits: arrayUnion({
        amount: amount,
        assignedBy: user.uid,
        assignedAt: new Date()
      })
    });

    batch.update(orgRef, {
      credits: credits - amount
    });

    await batch.commit();
    setCredits(credits - amount);
    alert('Credits assigned successfully.');
  };

  useEffect(() => {
    if (userOrg) {
      const teamQuery = query(collection(db, 'organizations', userOrg.id, 'teams'));
      const unsubscribeTeams = onSnapshot(teamQuery, async (snapshot) => {
        const userTeams = await Promise.all(snapshot.docs.map(async (teamDoc) => {
          const teamData = teamDoc.data();
          const members = await Promise.all(teamData.members.map(async (memberId) => {
            const memberDoc = await getDoc(doc(db, 'users', memberId));
            const memberData = memberDoc.data();

            let status = 'pending';
            let statusDate = null;

            if (memberId === teamData.createdBy) {
              status = 'Created';
              statusDate = teamData.createdAt;
            } else {
              const acceptedQuery = query(collection(db, 'users', memberId, 'acceptedInvites'), where('teamId', '==', teamDoc.id));
              const acceptedSnapshot = await getDocs(acceptedQuery);
              if (!acceptedSnapshot.empty) {
                const acceptedData = acceptedSnapshot.docs[0].data();
                status = 'accepted';
                statusDate = acceptedData.acceptedAt;
              } else {
                const declinedQuery = query(collection(db, 'users', memberId, 'declinedInvites'), where('teamId', '==', teamDoc.id));
                const declinedSnapshot = await getDocs(declinedQuery);
                if (!declinedSnapshot.empty) {
                  const declinedData = declinedSnapshot.docs[0].data();
                  status = 'declined';
                  statusDate = declinedData.declinedAt;
                }
              }
            }

            return { ...memberData, status, statusDate };
          }));
          return { id: teamDoc.id, ...teamData, members };
        }));
        setTeams(userTeams);
      });

      return () => unsubscribeTeams();
    }
  }, [userOrg]);

  useEffect(() => {
    if (userOrg) {
      const rooms = [
        { name: `Org Chat: ${userOrg.name}`, id: userOrg.id },
        ...teams.map(team => ({ name: `Team Chat: ${team.name}`, id: team.id }))
      ];
      setChatRooms(rooms);
    }
  }, [teams, userOrg]);

  useEffect(() => {
    if (currentChat && userOrg) {
      const chatCollection = currentChat.includes('Team') ? 'teams' : 'organizations';
      const chatId = currentChat.includes('Team') ? currentChat.split(': ')[1] : userOrg.id;
      if (!chatId) return;  // Ensure chatId is available before querying
      const q = query(collection(db, 'organizations', userOrg.id, chatCollection === 'teams' ? `teams/${chatId}/messages` : 'messages'), orderBy('timestamp', 'asc')); // Change here: order by timestamp
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const messages = snapshot.docs.map(doc => doc.data());
        setChatMessages(messages);
      });

      return () => unsubscribe();
    }
  }, [currentChat, userOrg]);

  const handleCreateOrg = async () => {
    if (userOrg) {
      alert('You already have an organization.');
      return;
    }
    try {
      const orgDoc = await addDoc(collection(db, 'organizations'), {
        name: orgName,
        admin: user.uid,
      });
      setUserOrg({ id: orgDoc.id, name: orgName, admin: user.uid });
      await updateDoc(doc(db, 'users', user.uid), {
        orgId: orgDoc.id,
        orgName: orgName,
        userType: 'business',
        isOrgAdmin: true,
      });
      await logChange(user.uid, `Created organization ${orgName}`);
      alert('Organization created successfully!');
    } catch (error) {
      console.error('Error creating organization: ', error);
    }
  };

  const handleCreateTeam = async () => {
    if (!userOrg) {
      alert('Please create an organization first.');
      return;
    }
    try {
      const teamDoc = await addDoc(collection(db, 'organizations', userOrg.id, 'teams'), {
        name: newTeam,
        members: [user.uid],
      });
      setTeams([...teams, { id: teamDoc.id, name: newTeam, members: [user.uid] }]);
      setNewTeam('');
      await logChange(user.uid, `Created team ${newTeam} in organization ${userOrg.name}`);
    } catch (error) {
      console.error('Error creating team: ', error);
    }
  };

  const handleSendInvitation = async (email) => {
    if (selectedTeams.length === 0) {
      alert('Please select at least one team.');
      return;
    }

    try {
      const userQuery = query(collection(db, 'users'), where('email', '==', email));
      const userSnapshot = await getDocs(userQuery);

      if (userSnapshot.empty) {
        alert('Invitation has been sent if the user exists and is not already in another organization.');
      } else {
        const userDoc = userSnapshot.docs[0];
        const userData = userDoc.data();

        if (userData.orgId && userData.orgId !== userOrg.id) {
          alert('The user is already part of a different organization.');
        } else {
          const batch = writeBatch(db);
          selectedTeams.forEach(teamId => {
            const team = teams.find(t => t.id === teamId);
            const invitationData = {
              recipient: email,
              recipientId: userDoc.id,
              orgId: userOrg.id,
              orgName: userOrg.name,
              teamId: teamId,
              teamName: team ? team.name : 'Unknown Team',
              status: 'pending',
              createdAt: new Date(),
              expiryDate: new Date(Date.now() + 7 * 24 * 60 * 60 * 1000)
            };
            const inviteRef = doc(collection(db, 'users', userDoc.id, 'pendingInvites'));
            batch.set(inviteRef, invitationData);
          });
          await batch.commit()
            .then(() => {
              alert('Invitation has been sent if the user exists and is not already in another organization.');
              selectedTeams.forEach(async (teamId) => {
                const team = teams.find(t => t.id === teamId);
                await logChange(user.uid, `Sent invitation to ${email} to join team ${team ? team.name : 'Unknown Team'} in organization ${userOrg.name}`);
              });
            })
            .catch((error) => {
              console.error('Error sending invitation:', error.message);
            });
        }
      }
    } catch (error) {
      console.error('Error fetching user for invitation:', error.message);
    }
  };

  const handleAcceptInvitation = async (invitation) => {
    try {
      if (user) {
        const userUpdate = {
          orgId: invitation.orgId,
          orgName: invitation.orgName,
        };

        await updateDoc(doc(db, 'users', user.uid), userUpdate);

        const batch = writeBatch(db);
        const teamRef = doc(db, 'organizations', invitation.orgId, 'teams', invitation.teamId);
        batch.update(teamRef, {
          members: arrayUnion(user.uid),
        });

        await batch.commit();

        const invitationRef = doc(db, 'users', user.uid, 'pendingInvites', invitation.id);
        const acceptedRef = doc(db, 'users', user.uid, 'acceptedInvites', invitation.id);
        const invitationData = { ...invitation, status: 'accepted', acceptedAt: new Date() };

        await deleteDoc(invitationRef);
        await setDoc(acceptedRef, invitationData);

        alert('Invitation accepted!');
        setInvitations(invitations.filter(inv => inv.id !== invitation.id));
        await logChange(user.uid, `Accepted invitation to join team ${invitation.teamName} in organization ${invitation.orgName}`);
      }
    } catch (error) {
      console.error('Error accepting invitation:', error.message);
    }
  };

  const handleDeclineInvitation = async (invitation) => {
    try {
      if (user) {
        const invitationRef = doc(db, 'users', user.uid, 'pendingInvites', invitation.id);
        const declinedRef = doc(db, 'users', user.uid, 'declinedInvites', invitation.id);
        const invitationData = { ...invitation, status: 'declined', declinedAt: new Date() };

        await deleteDoc(invitationRef);
        await setDoc(declinedRef, invitationData);

        alert('Invitation declined!');
        setInvitations(invitations.filter(inv => inv.id !== invitation.id));
        await logChange(user.uid, `Declined invitation to join team ${invitation.teamName} in organization ${invitation.orgName}`);
      }
    } catch (error) {
      console.error('Error declining invitation:', error.message);
    }
  };

  const handleTeamSelection = (teamId) => {
    setSelectedTeams(prevSelectedTeams =>
      prevSelectedTeams.includes(teamId)
        ? prevSelectedTeams.filter(id => id !== teamId)
        : [...prevSelectedTeams, teamId]
    );
  };

  const openChat = (chat) => {
    setCurrentChat(chat);
    setChatOpen(true);
    setChatMinimized(false);
  };

  const toggleChat = (e) => {
    if (e.target.tagName !== 'SELECT') { // Prevent toggling when clicking on the dropdown
      setChatMinimized(!chatMinimized);
    }
  };

  const closeChat = (e) => {
    e.stopPropagation();
    setChatMinimized(true);
  };

// Update the handleSendMessage function to include the selected color
const handleSendMessage = async () => {
  if (newMessage.trim()) {
    try {
      const userDoc = await getDoc(doc(db, 'users', user.uid));
      const userData = userDoc.data();
      const senderName = `${userData.firstName} ${userData.lastName}`;
      const chatCollection = currentChat.includes('Team') ? `teams/${currentChat.split(': ')[1]}/messages` : 'messages';
      await addDoc(collection(db, 'organizations', userOrg.id, chatCollection), {
        text: newMessage,
        sender: user.email,
        senderName: senderName,
        color: userColor, // Use the selected color
        timestamp: new Date(),
      });
      setNewMessage('');
      await logChange(user.uid, `Sent message in ${currentChat}`);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  }
};


  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const formatDate = (date) => {
    if (!date || !date.seconds) {
      return 'Unknown Date';
    }
    const d = new Date(date.seconds * 1000);
    return `${('0' + d.getDate()).slice(-2)}/${('0' + (d.getMonth() + 1)).slice(-2)}/${d.getFullYear()}`;
  };

  const getUserName = async (userId) => {
    const userDoc = await getDoc(doc(db, 'users', userId));
    const userData = userDoc.data();
    return `${userData.firstName} ${userData.lastName}`;
  };

  const getUserColor = (name) => {
    const colors = [
      '#FF5733', '#33FF57', '#3357FF', '#800000', '#FF8333', // Replaced pink (#FF33A1) with maroon (#800000)
      '#33FFF3', '#F333FF', '#8333FF', '#33FF83', '#FF3385'
    ];
    const firstLetter = name.charAt(0).toUpperCase();
    const colorIndex = firstLetter.charCodeAt(0) % colors.length;
    return colors[colorIndex];
  };

  useEffect(() => {
    if (user) {
      const q = query(collection(db, 'users', user.uid, 'pendingInvites'));
      const unsubscribe = onSnapshot(q, async (snapshot) => {
        const invites = await Promise.all(snapshot.docs.map(async (docSnapshot) => {
          const data = docSnapshot.data();
          if (!data.orgId || !data.teamId) {
            return null;
          }
          const teamDoc = await getDoc(doc(db, 'organizations', data.orgId, 'teams', data.teamId));
          const teamData = teamDoc.data();
          const userName = await getUserName(data.recipientId);
          return {
            id: docSnapshot.id,
            ...data,
            teamName: teamData ? teamData.name : 'Unknown Team',
            userName
          };
        }));
        setInvitations(invites.filter(invite => invite !== null));
      });

      return () => unsubscribe();
    }
  }, [user]);


  return (
    <div className="teams-page">
      <div className="teams-container">
        <div className="main-container">
          <h2>Teams</h2>
          {!userOrg ? (
            <div className="create-org">
              <h3>Create Organization</h3>
              <input
                type="text"
                placeholder="Organization Name"
                value={orgName}
                onChange={(e) => setOrgName(e.target.value)}
              />
              <button onClick={handleCreateOrg}>Create</button>
            </div>
          ) : (
            <>
              <div>
                <h3>Organization: {userOrg.name}</h3>
                <Balance />
              </div>
              {isAdmin && (
                <div className="create-team">
                  <h3>Create Team</h3>
                  <input
                    type="text"
                    placeholder="Team Name"
                    value={newTeam}
                    onChange={(e) => setNewTeam(e.target.value)}
                  />
                  <button onClick={handleCreateTeam}>Create</button>
                </div>
              )}
              {teams.length > 0 && (
                <div className="teams">
                  <h3>Teams</h3>
                  {teams.map((team, index) => (
                    <div key={index} className="team-container">
                      <div className="team-header" onClick={() => toggleTeamExpansion(team.id)}>
                        <span>{team.name}</span>
                      </div>
                      {expandedTeams.includes(team.id) && (
                        <div className="team-members">
                          {team.members && team.members.map((member, idx) => (
                            <div key={idx} className="team-member">
                              <Link to={`/profile/${member.id}`}>{`${member.firstName} ${member.lastName}`}</Link>
                              <span>{` - ${member.email}`}</span>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              )}
              <div>
                <h3>Assign Credits</h3>
                <input type="number" placeholder="Amount" id="creditAmount" />
                <input type="text" placeholder="Recipient User ID" id="recipientId" />
                <button onClick={() => {
                  const amount = parseInt(document.getElementById('creditAmount').value);
                  const recipientId = document.getElementById('recipientId').value;
                  assignCredits(amount, recipientId);
                }}>
                  Assign Credits
                </button>
              </div>
            </>
          )}
          <div className="send-invitation">
            <h3>Send Invitation</h3>
            <input
              type="email"
              placeholder="User Email"
              onKeyDown={(e) => {
                if (e.key === 'Enter') handleSendInvitation(e.target.value);
              }}
            />
            <button onClick={() => handleSendInvitation(document.querySelector('.send-invitation input').value)}>Send</button>
            {teams.length > 0 && (
              <div className="team-selection">
                <h4>Select Teams</h4>
                {teams.map((team) => (
                  <div key={team.id}>
                    <input
                      type="checkbox"
                      id={team.id}
                      value={team.id}
                      onChange={() => handleTeamSelection(team.id)}
                      disabled={team.members.includes(user.email) && team.members.some(member => member !== user.uid)}
                    />
                    <label htmlFor={team.id}>{team.name}</label>
                  </div>
                ))}
              </div>
            )}
          </div>
          {invitations.length > 0 && (
            <div className="invitations">
              <h3>Invitations</h3>
              {invitations.map((invitation, index) => (
                <div key={index} className="invitation">
                  <button onClick={() => handleAcceptInvitation(invitation)}>Accept</button>
                  <button onClick={() => handleDeclineInvitation(invitation)}>Decline</button>
                  <span>{`${invitation.userName} invites you to join ${invitation.orgName}: ${invitation.teamName} on ${formatDate(invitation.createdAt)}`}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        {chatOpen && (
          <div className={`chat-popup ${chatMinimized ? 'minimized' : ''}`}>
            <div className="chat-header" onClick={toggleChat}>
              <div className="chat-header-content">
                <select
                  onChange={(e) => {
                    if (e.target.value === 'close') {
                      closeChat(e);
                    } else {
                      openChat(e.target.value);
                    }
                  }}
                  onClick={(e) => e.stopPropagation()}
                  style={{ height: 'calc(100% - 10px)', width: 'calc(100% - 30px)', margin: '5px' }} // Increased height, width, and added margin for padding
                  className={chatMinimized ? 'dropdown-up' : ''} // Add class for upward display
                >
                  <option value="placeholder" disabled selected>
                    Select Chat Room
                  </option>
                  <option value="close">Close Chat</option>
                  {chatRooms.map((room, index) => (
                    <option key={index} value={room.name}>{room.name}</option>
                  ))}
                </select>
                {!chatMinimized && (
                  <button className="close-chat" onClick={closeChat} style={{ marginLeft: 'auto', marginRight: '5px' }}>X</button>
                )}
              </div>
            </div>
            {!chatMinimized && (
              <>
<div className="chat-body" ref={chatBodyRef}>
  {chatMessages.map((msg, index) => (
    <div key={index}>
      <strong style={{ color: msg.color || '#000000' }}>{msg.senderName || msg.sender}</strong>: <span style={{ color: '#000' }}>{msg.text}</span>
    </div>
  ))}
</div>

<div className="chat-footer">

  <input
    type="text"
    placeholder="Type a message..."
    value={newMessage}
    onChange={(e) => setNewMessage(e.target.value)}
    onKeyDown={handleKeyPress}
  />
  <button onClick={handleSendMessage}>Send</button>
</div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Teams;
