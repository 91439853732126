import React, { useState } from 'react';
import { useAuth } from '../../hooks/useAuth';
import { doc, collection, addDoc, setDoc, getDoc, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../config/firebase';
import { useStripe, useElements, CardElement, ElementsConsumer } from '@stripe/react-stripe-js';
import axios from 'axios';


const AddCardDetails = ({ onClose }) => {
  const { user } = useAuth();
  const stripe = useStripe();
  const elements = useElements();
  const [cardDetails, setCardDetails] = useState({
    billingAddress: '',
    city: '',
    state: '',
    zip: '',
    country: '',
  });
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCardDetails(prevDetails => ({ ...prevDetails, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (!stripe || !elements) return;

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      console.error('CardElement not found');
      setLoading(false);
      return;
    }

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        address: {
          line1: cardDetails.billingAddress,
          city: cardDetails.city,
          state: cardDetails.state,
          postal_code: cardDetails.zip,
          country: cardDetails.country,
        },
      },
    });

    if (error) {
      console.error('Error creating payment method:', error);
      setLoading(false);
      return;
    }

    const last4 = paymentMethod.card.last4;
    const expiryDate = `${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`;

    try {
      const customerRef = doc(db, 'users', user.uid, 'billing', 'default');
      const customerDoc = await getDoc(customerRef);
      let customerId;

      if (customerDoc.exists()) {
        customerId = customerDoc.data().customerId;
      } else {
        const customerResponse = await axios.post('https://us-central1-data-jinx.cloudfunctions.net/api/create-customer', {
          userId: user.uid,
          email: user.email,
        });
        customerId = customerResponse.data.id;
        await setDoc(customerRef, { customerId });
      }

      // Check if the card already exists
      const cardsQuery = query(
        collection(customerRef, 'cards'),
        where('cardNumber', '==', `**** **** **** ${last4}`),
        where('expiryDate', '==', expiryDate)
      );
      const querySnapshot = await getDocs(cardsQuery);
      if (!querySnapshot.empty) {
        console.error('Card already exists');
        setLoading(false);
        return;
      }

      await axios.post('https://us-central1-data-jinx.cloudfunctions.net/api/attach-payment-method', {
        customerId,
        paymentMethodId: paymentMethod.id,
      });

      await addDoc(collection(customerRef, 'cards'), {
        paymentMethodId: paymentMethod.id,
        billingAddress: cardDetails.billingAddress,
        city: cardDetails.city,
        state: cardDetails.state,
        zip: cardDetails.zip,
        country: cardDetails.country,
        cardNumber: `**** **** **** ${last4}`,
        expiryDate: expiryDate,
        created: new Date(),
      });
      onClose();
    } catch (err) {
      console.error('Error adding card details:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="add-card-details">
      <h3>Add Card Details</h3>
      {loading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <CardElement />
        <input type="text" name="billingAddress" placeholder="Billing Address" value={cardDetails.billingAddress} onChange={handleChange} required />
        <input type="text" name="city" placeholder="City" value={cardDetails.city} onChange={handleChange} required />
        <input type="text" name="state" placeholder="State" value={cardDetails.state} onChange={handleChange} required />
        <input type="text" name="zip" placeholder="ZIP Code" value={cardDetails.zip} onChange={handleChange} required />
        <input type="text" name="country" placeholder="Country" value={cardDetails.country} onChange={handleChange} required />
        <button type="submit" disabled={loading}>
          {loading ? 'Adding...' : 'Add Card'}
        </button>
      </form>
      <button onClick={onClose}>Close</button>
    </div>
  );
};

const AddCardDetailsWithElements = (props) => (
  <ElementsConsumer>
    {({ stripe, elements }) => (
      <AddCardDetails {...props} stripe={stripe} elements={elements} />
    )}
  </ElementsConsumer>
);

export default AddCardDetailsWithElements;
