import React from 'react';
import './ProductDemo.css';
import stripeLogo from '../../assets/stripe-logo.png';

const StripePayment = () => {
  const isDarkMode = document.body.classList.contains('dark-mode');
  return (
    <section className={`stripe-payment ${isDarkMode ? 'dark-mode' : ''}`}>
      <h2>Stripe Payment Accepted</h2>
      <p>We accept payments securely through Stripe. Ensure your transactions are safe and secure with our reliable payment processing partner.</p>
      <img src={stripeLogo} alt="Stripe Logo" className="stripe-logo" />
    </section>
  );
}

// Ensure StripePayment is part of ProductDemo
const ProductDemo = () => {
  return (
    <section className="product-demo">
      <h2>See DataJinx in Action</h2>
      <div className="video-wrapper">
        <video controls>
          <source src="demo-video.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
      <StripePayment />
    </section>
  );
};


export default ProductDemo;
