// src/components/UserLogin.js

import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFirestore, doc, setDoc, serverTimestamp, getDoc } from 'firebase/firestore';
import { app } from '../config/firebase';
import './UserLogin.css';
import googleLogo from '../assets/google-logo.png';

const UserLogin = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [loginError, setLoginError] = useState('');
  const navigate = useNavigate();
  const auth = getAuth(app);
  const db = getFirestore(app);
  const googleProvider = new GoogleAuthProvider();

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoginError('');
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate('/projects');
    } catch (error) {
      console.error("Failed to log in user", error);
      setLoginError('Login unsuccessful. Please check your email and password.');
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setLoginError('Please enter your email address to reset your password.');
      return;
    }
    try {
      await sendPasswordResetEmail(auth, email);
      alert('If an account exists, a password reset email has been sent to your inbox.');
    } catch (error) {
      console.error("Error sending password reset email", error);
      setLoginError('Failed to send password reset email. Please try again.');
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const { displayName, email, uid } = user;
      let firstName = '';
      let lastName = '';

      if (displayName) {
        [firstName, lastName] = displayName.split(' ');
      }

      const userDoc = await getDoc(doc(db, 'users', uid));
      if (!userDoc.exists()) {
        await setDoc(doc(db, 'users', uid), {
          email,
          firstName: firstName || 'Unknown',
          lastName: lastName || 'Unknown',
          createdAt: serverTimestamp(),
        });
      }

      navigate('/projects');
    } catch (error) {
      console.error("Error signing in with Google", error);
      setLoginError('An error occurred while signing in with Google. Please try again.');
    }
  };

  return (
    <div className="pageContainer">
      <div className="userLoginContainer">
        <div className="userLoginForm">
          <h2>User Login</h2>
          {loginError && <div className="errorMessage">{loginError}</div>}
          <form onSubmit={handleLogin}>
            <div>
              <label htmlFor="email">Email:</label>
              <input
                type="email"
                id="email"
                name="email"
                value={email}
                onChange={handleEmailChange}
                required
              />
            </div>
            <div>
              <label htmlFor="password">Password:</label>
              <input
                type="password"
                id="password"
                name="password"
                value={password}
                onChange={handlePasswordChange}
                required
              />
            </div>
            <button type="submit">Login</button>
          </form>
          <button onClick={handleForgotPassword} className="forgotPasswordBtn">
            Forgot Password?
          </button>
          <button type="button" onClick={handleGoogleSignIn} className="socialSignInBtn google">
            <img src={googleLogo} alt="Google Logo" /> Continue with Google
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserLogin;
