import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFirestore, doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { app } from '../config/firebase'; // Import the initialized Firebase app
import './Signup.css'; // Ensure this points to the correct path of your CSS file
import googleLogo from '../assets/google-logo.png';

const auth = getAuth(app); // Get the auth instance using the initialized app
const db = getFirestore(app); // Get the Firestore instance using the initialized app
const googleProvider = new GoogleAuthProvider();

const SignUp = () => {
  const navigate = useNavigate(); // Initialize navigate function
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [acceptTerms, setAcceptTerms] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [signupError, setSignupError] = useState('');

  useEffect(() => {
    if (confirmPassword !== '') {
      setPasswordMismatch(password !== confirmPassword);
    }
  }, [password, confirmPassword]);

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert('Passwords do not match!');
      return;
    }

    if (!acceptTerms) {
      alert('You must accept the terms and conditions.');
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;

      // Add user data to Firestore
      await setDoc(doc(db, 'users', user.uid), {
        email: user.email,
        firstName,
        lastName,
        createdAt: serverTimestamp(),
      });

      navigate('/profile'); // Redirect user to projects upon successful signup
    } catch (error) {
      setSignupError('An error occurred while creating your account. Please try again.');
      console.error(error.message);
    }
  };

  const handleGoogleSignIn = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      const user = result.user;
      const { displayName, email, uid } = user;
      let firstName = '';
      let lastName = '';

      if (displayName) {
        [firstName, lastName] = displayName.split(' ');
      }

      // Add user data to Firestore
      await setDoc(doc(db, 'users', uid), {
        email,
        firstName: firstName || 'Unknown',
        lastName: lastName || 'Unknown',
        createdAt: serverTimestamp(),
      });

      navigate('/profile');
    } catch (error) {
      setSignupError('An error occurred while signing in with Google. Please try again.');
      console.error(error.message);
    }
  };

  return (
    <div className="signUpContainer">
      <div className="signUpForm">
        <form onSubmit={handleSignUp}>
          <h2>Sign Up</h2>
          {signupError && <div style={{ color: 'red' }}>{signupError}</div>}
          <button type="button" onClick={handleGoogleSignIn} className="socialSignInBtn google">
            <img src={googleLogo} alt="Google Logo" /> Continue with Google
          </button>
          <div className="or-separator">Or</div>
          <div className="nameContainer">
            <div>
              <label>First Name:</label>
              <input type="text" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
            </div>
            <div>
              <label>Last Name:</label>
              <input type="text" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
            </div>
          </div>
          <div>
            <label>Email:</label>
            <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </div>
          <div>
            <label>Password:</label>
            <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
          </div>
          <div>
            <label>Confirm Password:</label>
            <input type="password" value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} required />
            {passwordMismatch && <div style={{ color: 'red' }}>Passwords do not match!</div>}
          </div>
          <div>
            <label>
              <input type="checkbox" checked={acceptTerms} onChange={(e) => setAcceptTerms(e.target.checked)} />
              I accept the <a href="/terms" target="_blank" rel="noopener noreferrer">Terms and Conditions</a>
            </label>
          </div>
          <button type="submit" className="emailSignUpBtn">Create Account</button>
          <div>
            <p>Already have an account? <a href="/login">Log In</a></p>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUp;
