// src/components/Header.js

import React, { useEffect, useState, useRef } from 'react';
import './Header.css';
import Logo from './Logo';
import { Link, useNavigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

const Header = () => {
  const [user, setUser] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();
  const auth = getAuth();
  const menuRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        menuRef.current &&
        !menuRef.current.contains(event.target) &&
        event.button !== 2
      ) {
        setIsMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setUser(currentUser);
    });
    return () => unsubscribe();
  }, [auth]);

  const handleSignOut = () => {
    signOut(auth)
      .then(() => {
        console.log("User signed out");
        setIsMenuOpen(false);
        navigate('/');
      })
      .catch((error) => {
        console.error("Sign out error", error);
      });
  };

  const handleNavigation = (path) => {
    setIsMenuOpen(false); // Close the side-menu
    navigate(path);
  };

  return (
    <header>
      <div className="header">
        <div className="menu-icon" onClick={toggleMenu}>
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
        <Logo className="logo" style={{ marginLeft: '10px' }} />
        <div className="icons"></div>
      </div>
      <div className={`side-menu ${isMenuOpen ? 'open' : ''}`} ref={menuRef}>
        <div className="close-icon" onClick={toggleMenu}>&times;</div>
        <Link to="/" onClick={() => handleNavigation('/')}>Home</Link>
        <div className="divider"></div>
        {user ? (
          <>
            <Link to="/dashboard" onClick={() => handleNavigation('/dashboard')}>Dashboard</Link>
            <Link to="/projects" onClick={() => handleNavigation('/projects')}>Projects</Link>
            <div className="divider"></div>
            <Link to="/profile" onClick={() => handleNavigation('/profile')}>My Profile</Link>
            <Link to="/teams" onClick={() => handleNavigation('/teams')}>Teams</Link>
            <Link to="/notifications" onClick={() => handleNavigation('/notifications')}>Notifications</Link>
            <div className="divider"></div>
            <Link to="/billing" onClick={() => handleNavigation('/billing')}>Billing</Link>
            <div className="divider"></div>
            <Link to="/settings" onClick={() => handleNavigation('/settings')}>Settings</Link>
            <Link to="/" onClick={handleSignOut}>Sign Out</Link>
            <div className="divider"></div>
          </>
        ) : (
          <>
            <Link to="/login" onClick={() => handleNavigation('/login')}>Login</Link>
            <Link to="/signup" onClick={() => handleNavigation('/signup')}>Sign Up</Link>
            <div className="divider"></div>
          </>
        )}
        <Link to="/faq" onClick={() => handleNavigation('/faq')}>FAQ</Link>
      </div>
    </header>
  );
};

export default Header;
