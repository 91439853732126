// src/config/firebase.js

import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAnalytics } from 'firebase/analytics';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBJ5i6KqfW1-zQgwP2ejgViCjQu4j9LJL4",
  authDomain: "data-jinx.firebaseapp.com",
  // authDomain: "datajinx.com",
  projectId: "data-jinx",
  storageBucket: "data-jinx.appspot.com",
  messagingSenderId: "345092995025",
  appId: "1:345092995025:web:e89557421267e1501592f0",
  measurementId: "G-L5FWJEGDF4"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const auth = getAuth(app);
getAnalytics(app);

export { app, db, auth };

