// Last 24 hours not working
import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../config/firebase';
import { useAuth } from '../hooks/useAuth';
import './UserDashboard.css';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  LinearScale,
  TimeScale,
  CategoryScale
} from 'chart.js';
import 'chartjs-adapter-date-fns';
import { Pie, Line } from 'react-chartjs-2';

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  LineElement,
  PointElement,
  LinearScale,
  TimeScale,
  CategoryScale
);

const UserDashboard = () => {
  const { user } = useAuth();
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [credits, setCredits] = useState(0);
  const [recentProjects, setRecentProjects] = useState([]);
  const [starredProjects, setStarredProjects] = useState([]);
  const [recentJobs, setRecentJobs] = useState([]);
  const [expandedSegmentData, setExpandedSegmentData] = useState([]);
  const [expandedChartType, setExpandedChartType] = useState(null);
  const [teamSize] = useState(0); // Placeholder value
  const [orgSize] = useState(0); // Placeholder value
  const [expandedSections, setExpandedSections] = useState({
    recentProjects: false,
    starredProjects: false,
    recentJobs: false,
    projectDetails: false
  });
  const [period, setPeriod] = useState('');

  useEffect(() => {
    if (user) {
      const fetchProjectsAndJobs = async () => {
        const projectsQuery = query(collection(db, 'projects'), where('userId', '==', user.uid));
        const querySnapshot = await getDocs(projectsQuery);
        const projectsData = [];
        const jobsData = [];

        for (const doc of querySnapshot.docs) {
          const projectData = { id: doc.id, ...doc.data() };
          const currentJobsSnapshot = await getDocs(collection(db, 'projects', doc.id, 'currentJobs'));
          const pausedJobsSnapshot = await getDocs(collection(db, 'projects', doc.id, 'pausedJobs'));
          const completedJobsSnapshot = await getDocs(collection(db, 'projects', doc.id, 'completedJobs'));
          const deletedJobsSnapshot = await getDocs(collection(db, 'projects', doc.id, 'deletedJobs'));

          projectData.jobs = {
            active: currentJobsSnapshot.docs.map(job => ({ ...job.data(), projectName: projectData.name })),
            paused: pausedJobsSnapshot.docs.map(job => ({ ...job.data(), projectName: projectData.name })),
            completed: completedJobsSnapshot.docs.map(job => ({ ...job.data(), projectName: projectData.name })),
            deleted: deletedJobsSnapshot.docs.map(job => ({ ...job.data(), projectName: projectData.name })),
          };

          jobsData.push(...projectData.jobs.active, ...projectData.jobs.paused, ...projectData.jobs.completed, ...projectData.jobs.deleted);
          projectsData.push(projectData);
        }

        setProjects(projectsData);
        setRecentJobs(jobsData.slice(0, 5));
        setLoading(false);
      };

      fetchProjectsAndJobs();
    }
  }, [user]);

  useEffect(() => {
    setCredits(user ? 1000 : 0);
    setRecentProjects(projects.slice(0, 5));
    setStarredProjects(projects.filter(p => p.starred).slice(0, 5));
  }, [projects, user]);

  const handlePieClick = (elements, event, chartType) => {
    if (elements.length > 0) {
      const segmentIndex = elements[0].index;
      const statusLabels = chartType === 'project' ? ['starred', 'active', 'backlogged', 'deleted'] : ['active', 'paused', 'completed', 'deleted'];
      const status = statusLabels[segmentIndex];
      const filteredData = chartType === 'project'
        ? projects.filter(p => (status === 'starred' ? p.starred : p.status === status))
        : projects.flatMap(p => p.jobs ? p.jobs[status] : []);
      setExpandedSegmentData(filteredData);
      setExpandedChartType(chartType);
      setExpandedSections(prevState => ({
        ...prevState,
        projectDetails: true
      }));
    }
  };

  const filterDataByPeriod = (data, period) => {
    const now = new Date();
    let startDate;
    switch (period) {
      case 'last60Minutes':
        startDate = new Date(now.getTime() - 60 * 60 * 1000);
        break;
      case 'last24Hours':
        startDate = new Date(now.getTime() - 24 * 60 * 60 * 1000);
        break;
      case 'last7Days':
        startDate = new Date(now.getTime() - 7 * 24 * 60 * 60 * 1000);
        break;
      case 'last4Weeks':
        startDate = new Date(now.getTime() - 4 * 7 * 24 * 60 * 60 * 1000);
        break;
      case 'last12Months':
        startDate = new Date(now.getTime() - 12 * 30 * 24 * 60 * 60 * 1000);
        break;
      case 'last3Years':
        startDate = new Date(now.getTime() - 3 * 365 * 24 * 60 * 60 * 1000);
        break;
      default:
        return data;
    }
    return data.filter(item => new Date(item.createdAt) >= startDate);
  };

  const filteredProjects = filterDataByPeriod(projects, period);
  const filteredJobs = filterDataByPeriod(projects.flatMap(p => p.jobs ? [...p.jobs.active, ...p.jobs.paused, ...p.jobs.completed, ...p.jobs.deleted] : []), period);

  const projectStatusData = {
    labels: ['Starred', 'Active', 'Backlogged', 'Deleted'],
    datasets: [
      {
        data: [
          filteredProjects.filter(p => p.starred).length,
          filteredProjects.filter(p => p.status === 'active').length,
          filteredProjects.filter(p => p.status === 'backlogged').length,
          filteredProjects.filter(p => p.status === 'deleted').length,
        ],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#AA00FF'],
      },
    ],
  };

  const jobStatusData = {
    labels: ['Active', 'Paused', 'Completed', 'Deleted'],
    datasets: [
      {
        data: [
          filteredJobs.filter(job => job.status === 'active').length,
          filteredJobs.filter(job => job.status === 'paused').length,
          filteredJobs.filter(job => job.status === 'completed').length,
          filteredJobs.filter(job => job.status === 'deleted').length,
        ],
        backgroundColor: ['#FF6384', '#36A2EB', '#FFCE56', '#AA00FF'],
      },
    ],
  };

  const projectCreationData = {
    labels: filteredProjects.map(p => new Date(p.createdAt).toLocaleDateString()),
    datasets: [
      {
        label: 'Active',
        data: filteredProjects.filter(p => p.status === 'active').map((p, i) => ({ x: new Date(p.createdAt), y: i + 1 })),
        fill: false,
        borderColor: '#36A2EB'
      },
      {
        label: 'Backlogged',
        data: filteredProjects.filter(p => p.status === 'backlogged').map((p, i) => ({ x: new Date(p.createdAt), y: i + 1 })),
        fill: false,
        borderColor: '#FFCE56'
      },
      {
        label: 'Deleted',
        data: filteredProjects.filter(p => p.status === 'deleted').map((p, i) => ({ x: new Date(p.createdAt), y: i + 1 })),
        fill: false,
        borderColor: '#AA00FF'
      },
      {
        label: 'Starred',
        data: filteredProjects.filter(p => p.starred).map((p, i) => ({ x: new Date(p.createdAt), y: i + 1 })),
        fill: false,
        borderColor: '#FF6384'
      }
    ]
  };

  const jobCreationData = {
    labels: filteredJobs.map(job => new Date(job.createdAt).toLocaleDateString()),
    datasets: [
      {
        label: 'Active',
        data: filteredJobs.filter(job => job.status === 'active').map((job, i) => ({ x: new Date(job.createdAt), y: i + 1 })),
        fill: false,
        borderColor: '#36A2EB'
      },
      {
        label: 'Paused',
        data: filteredJobs.filter(job => job.status === 'paused').map((job, i) => ({ x: new Date(job.createdAt), y: i + 1 })),
        fill: false,
        borderColor: '#FFCE56'
      },
      {
        label: 'Completed',
        data: filteredJobs.filter(job => job.status === 'completed').map((job, i) => ({ x: new Date(job.createdAt), y: i + 1 })),
        fill: false,
        borderColor: '#AA00FF'
      },
      {
        label: 'Deleted',
        data: filteredJobs.filter(job => job.status === 'deleted').map((job, i) => ({ x: new Date(job.createdAt), y: i + 1 })),
        fill: false,
        borderColor: '#FF6384'
      }
    ]
  };

  const getTimeUnitAndStepSize = (period) => {
    switch (period) {
      case 'last60Minutes':
        return { unit: 'minute', stepSize: 5 };
      case 'last24Hours':
        return { unit: 'hour', stepSize: 1 };
      case 'last7Days':
        return { unit: 'day', stepSize: 1 };
      case 'last4Weeks':
        return { unit: 'week', stepSize: 1 };
      case 'last12Months':
        return { unit: 'month', stepSize: 1 };
      case 'last3Years':
        return { unit: 'year', stepSize: 1 };
      default:
        return { unit: 'year', stepSize: 1 };
    }
  };

  const toggleSection = (section) => {
    setExpandedSections(prevState => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };

  const refreshDashboard = () => {
    window.location.reload();
  };

  if (loading) {
    return (
      <div className="spinner-overlay">
        <div className="spinner"></div>
      </div>
    );
  }

  const timeUnitAndStepSize = getTimeUnitAndStepSize(period);

  return (
    <div className="dashboard">
      <h1>Welcome, {user.displayName}</h1>
      <button className="refresh-dashboard" onClick={refreshDashboard}>↻</button>
      <select className="select-period" onChange={(e) => setPeriod(e.target.value)}>
        <option value="">All Time</option>
        <option value="last60Minutes">Last 60 Minutes</option>
        <option value="last24Hours">Last 24 Hours</option>
        <option value="last7Days">Last 7 Days</option>
        <option value="last4Weeks">Last 4 Weeks</option>
        <option value="last12Months">Last 12 Months</option>
        <option value="last3Years">Last 3 Years</option>
      </select>
      <div className="stats">
        <div className="stat-item">
          <h2>Credits Available</h2>
          <p>{credits}</p>
        </div>
      </div>

      <div className="project-lists">
        <div className="project-list-item">
          <h2 className="clickable" onClick={() => toggleSection('recentProjects')}>Recent Projects</h2>
          {expandedSections.recentProjects && (
            <div className="scrollable-container">
              <ul>
                {recentProjects.map(project => (
                  <li key={project.id}><a href={`/projects/${project.id}`}>{project.name}</a></li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <div className="project-list-item">
          <h2 className="clickable" onClick={() => toggleSection('starredProjects')}>Favourite Projects</h2>
          {expandedSections.starredProjects && (
            <div className="scrollable-container">
              <ul>
                {starredProjects.map(project => (
                  <li key={project.id}><a href={`/projects/${project.id}`}>{project.name}</a></li>
                ))}
              </ul>
            </div>
          )}
        </div>
      </div>

      <div className="charts">
        <div className="chart-container">
          <h2>Project Distribution</h2>
          <Pie data={projectStatusData} options={{ maintainAspectRatio: false, plugins: { tooltip: { callbacks: { label: (context) => `${context.label}: ${context.raw}` } }, legend: { position: 'right' } }, onClick: (evt, elements) => handlePieClick(elements, evt, 'project') }} />
        </div>
        <div className="chart-container">
          <h2>Job Status Distribution</h2>
          <Pie data={jobStatusData} options={{ maintainAspectRatio: false, plugins: { tooltip: { callbacks: { label: (context) => `${context.label}: ${context.raw}` } }, legend: { position: 'right' } }, onClick: (evt, elements) => handlePieClick(elements, evt, 'job') }} />
        </div>
      </div>

      <div className="charts">
        <div className="chart-container">
          <h2>Project Creation Over Time</h2>
          <Line
            data={projectCreationData}
            options={{
              maintainAspectRatio: false,
              scales: {
                x: {
                  type: 'time',
                  time: { unit: timeUnitAndStepSize.unit },
                  ticks: { maxTicksLimit: 12, stepSize: timeUnitAndStepSize.stepSize }
                },
                y: { beginAtZero: true, stepSize: 1 }
              }
            }}
          />
        </div>
        <div className="chart-container">
          <h2>Job Creation Over Time</h2>
          <Line
            data={jobCreationData}
            options={{
              maintainAspectRatio: false,
              scales: {
                x: {
                  type: 'time',
                  time: { unit: timeUnitAndStepSize.unit },
                  ticks: { maxTicksLimit: 12, stepSize: timeUnitAndStepSize.stepSize }
                },
                y: { beginAtZero: true, stepSize: 1 }
              }
            }}
          />
        </div>
      </div>

      <h2 className="clickable" onClick={() => toggleSection('recentJobs')}>Recent Jobs</h2>
      {expandedSections.recentJobs && (
        <div className="scrollable-container">
          <ul>
            {recentJobs.map((job, index) => (
              <li key={index}>{job.projectName} - {job.name} ({job.status})</li>
            ))}
          </ul>
        </div>
      )}

      <div className="stats">
        <div className="stat-item">
          <h2>Team Size</h2>
          <p>{teamSize}</p>
        </div>
        <div className="stat-item">
          <h2>Organization Size</h2>
          <p>{orgSize}</p>
        </div>
      </div>

      {expandedSegmentData.length > 0 && (
        <div className="expanded-list">
          <h2>Projects Details</h2>
          <ul>
            {expandedSegmentData.map((item, index) => (
              <li key={index}><a href={`/projects/${item.projectName ? item.projectName : item.id}`}>{item.projectName ? `${item.projectName} - ${item.name}` : item.name}</a></li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default UserDashboard;
