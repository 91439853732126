// src/components/JobList.js

import React from 'react';
import { logChange } from '../../services/logService'; // Import the log service
import { useAuth } from '../../hooks/useAuth';

const JobList = ({ jobs, toggleAccordion, handleDeleteJob, handlePauseJob, handlePlayJob, selectedProject, predefinedJobs, handleCreateJob, expandedJobId }) => {
  const { user } = useAuth();

  const createJob = async (project, job) => {
    await handleCreateJob(project.id, job.id);
    await logChange(user.uid, `Created job ${job.title} in project ${project.name}`);
  };

  const pauseJob = async (project, job) => {
    if (job.status !== 'paused') { // Ensure the job isn't already paused
      await handlePauseJob(project.id, job.id, job.status);
      await logChange(user.uid, `Paused job ${job.title} in project ${project.name}`);
    }
  };

  const playJob = async (project, job) => {
    await handlePlayJob(project.id, job.id, job.status);
    await logChange(user.uid, `Resumed job ${job.title} in project ${project.name}`);
  };

  const deleteJob = async (project, job) => {
    if (job.status !== 'deleted') { // Ensure the job isn't already deleted
      await handleDeleteJob(project.id, job.id, job.status);
      await logChange(user.uid, `Deleted job ${job.title} in project ${project.name}`);
    }
  };

  return (
    <div className="job-list">
      {/* Removed predefined jobs container */}
      {jobs.map((jobCategory) => (
        <div key={jobCategory.status}>
          {jobCategory.jobs.map((job) => (
            <div
              key={job.id}
              className="job-item"
              onClick={(e) => {
                if (e.target.closest('.job-buttons')) return; // Prevents expanding when clicking buttons
                toggleAccordion(job.id);
              }}
            >
              <div className="job-header">
                <h4 className="job-title">{job.title}</h4>
                {job.status !== 'deleted' && (
                  <div className="job-buttons">
                    {job.status !== 'completed' && (
                      <>
                        {job.status !== 'paused' ? (
                          <button
                            className="pause-job-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              pauseJob(selectedProject, job);
                            }}
                          >
                            &#9208; {/* Pause button */}
                          </button>
                        ) : (
                          <button
                            className="play-job-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              playJob(selectedProject, job);
                            }}
                          >
                            ▶️ {/* Play button */}
                          </button>
                        )}
                        <button
                          className="delete-job-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            deleteJob(selectedProject, job);
                          }}
                        >
                          &times; {/* Delete button */}
                        </button>
                      </>
                    )}
                  </div>
                )}
              </div>
              {expandedJobId.includes(job.id) && ( // Check if job.id is in expandedJobId array
                <div className="job-content">
                  <p className="job-description">{job.description}</p>
                  <div className="job-status">{job.status}</div>
                  <ul className="task-list">
                    {job.tasks.map((task, index) => (
                      <li key={index} className={`task-item ${task.status}`}>
                        <span className="task-status">{task.status}</span>
                        <span className="task-name">{task.name}</span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          ))}
        </div>
      ))}
    </div>
  );
};

export default JobList;