// src/components/Settings.js

import React, { useState, useEffect } from 'react';
import DarkModeToggle from './DarkModeToggle';
import { useAuth } from '../hooks/useAuth';
import { logChange } from '../services/logService';
import { db } from '../config/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import './Settings.css'; // Add a CSS file for styles

const Settings = () => {
  const { user } = useAuth();
  const [color, setColor] = useState('#000000');

  useEffect(() => {
    const fetchColor = async () => {
      if (user) {
        const userDoc = await getDoc(doc(db, 'users', user.uid));
        if (userDoc.exists) {
          setColor(userDoc.data().color || '#000000');
        }
      }
    };
    fetchColor();
  }, [user]);

  const handleColorChange = async (e) => {
    const selectedColor = e.target.value;
    setColor(selectedColor);
    if (user) {
      await updateDoc(doc(db, 'users', user.uid), { color: selectedColor });
      logChange(user.uid, `Chat color set to ${selectedColor}`);
    }
  };

  const handleDarkModeChange = (isDarkMode) => {
    if (user) {
      logChange(user.uid, `Dark mode set to ${isDarkMode ? 'enabled' : 'disabled'}`);
    }
  };

  return (
    <div className="settings-container">
      <h1>Settings</h1>
      <div className="settings-section">
        <h2>Appearance</h2>
        <div className="setting-item">
          <label>Dark Mode:</label>
          <DarkModeToggle onToggle={handleDarkModeChange} />
        </div>
        <div className="setting-item">
          <label htmlFor="colorPicker">Choose your chat color:</label>
          <select id="colorPicker" value={color} onChange={handleColorChange}>
            <option value="#FF5733">Red</option>
            <option value="#33FF57">Green</option>
            <option value="#3357FF">Blue</option>
            <option value="#800000">Maroon</option>
            <option value="#FF8333">Orange</option>
            <option value="#33FFF3">Cyan</option>
            <option value="#F333FF">Magenta</option>
            <option value="#8333FF">Purple</option>
            <option value="#33FF83">Lime</option>
            <option value="#FF3385">Pink</option>
          </select>
        </div>
      </div>
    </div>
  );
};

export default Settings;
