import React from 'react';
import './FeaturesSection.css';

// Add icons to features
import { FaSearch, FaDatabase, FaRobot } from 'react-icons/fa';

const FeaturesSection = () => {
  const isDarkMode = document.body.classList.contains('dark-mode');

  return (
    <section className={`features ${isDarkMode ? 'dark-mode' : ''}`}>
      <h2>Key Features</h2>
      <div className="features-grid">
        <div className="column">
          <FaSearch size={50} />
          <h1>Tier #1</h1>
          <h2>Heuristic Map Search / Search our Data Lake</h2>
          <p>Return a list of all online locations matching keywords in your search query</p>
        </div>
        <div className="column">
          <FaDatabase size={50} />
          <h1>Tier #2</h1>
          <h2>Clean & Consolidate</h2>
          <p>Includes Tier 1<br />
            Return a connection to a Snowflake DBMS that has compiled all of your data into a queryable table</p>
        </div>
        <div className="column">
          <FaRobot size={50} />
          <h1>Tier #3</h1>
          <h2>Create Custom AI</h2>
          <p>Includes Tier 1 and Tier 2</p>
          <p>Builds a custom AI model based on the consolidated data set, ask this AI anything.</p>
        </div>
      </div>
    </section>
  );
};


export default FeaturesSection;
