import React from 'react';
import { Tooltip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import { WithContext as ReactTags } from 'react-tag-input';

const JobForm = ({
  jobType,
  handleJobTypeChange,
  selectedJob,
  setSelectedJob,
  handleJobConfigChange,
  basicTags,
  advancedTags,
  handleDeleteBasicTag,
  handleAdditionBasicTag,
  handleDeleteAdvancedTag,
  handleAdditionAdvancedTag,
  handleCreateJob,
  isCreateJobEnabled,
  closeAddJobPopup,
  selectedProject,
  predefinedJobs,
  isJobSelectable,
}) => {
  return (
    <div className="add-job-popup">
      <div className="add-job-popup-content">
        <h3>Select a Job</h3>
        <div>
          <label>
            <input type="radio" value="Basic" checked={jobType === 'Basic'} onChange={handleJobTypeChange} />
            Basic
          </label>
          <label>
            <input type="radio" value="Advanced" checked={jobType === 'Advanced'} onChange={handleJobTypeChange} />
            Advanced
          </label>
        </div>
        <select onChange={(e) => setSelectedJob(e.target.value)} value={selectedJob}>
          <option value="" disabled>Please Select a Job</option>
          {predefinedJobs.map((job) => (
            <option key={job.id} value={job.id} disabled={!isJobSelectable(job.id)}>{job.title}</option>
          ))}
        </select>
        {selectedJob && (
          <>
            {jobType === 'Basic' && (
              <>
                <div className="form-group">
                  <label>Dropdown Field</label>
                  <select name="dropdownField" onChange={handleJobConfigChange} >
                    <option value="">Select an option</option>
                    <option value="option1">Option 1</option>
                    <option value="option2">Option 2</option>
                  </select>
                  <Tooltip title="Information about this field">
                    <InfoIcon />
                  </Tooltip>
                </div>
                <div className="form-group">
                  <label>Radio Button Selection</label>
                  <label>
                    <input type="radio" name="radioSelection" value="option1" onChange={handleJobConfigChange} />
                    Option 1
                  </label>
                  <label>
                    <input type="radio" name="radioSelection" value="option2" onChange={handleJobConfigChange} />
                    Option 2
                  </label>
                  <Tooltip title="Information about this field">
                    <InfoIcon />
                  </Tooltip>
                </div>
                <div className="form-group">
                  <label>Checkbox Selection</label>
                  <label>
                    <input type="checkbox" name="checkboxSelection" onChange={handleJobConfigChange} />
                    Option
                  </label>
                  <Tooltip title="Information about this field">
                    <InfoIcon />
                  </Tooltip>
                </div>
                <div className="form-group">
                  <label>Text Field</label>
                  <input type="text" name="textField" onChange={handleJobConfigChange} />
                  <Tooltip title="Information about this field">
                    <InfoIcon />
                  </Tooltip>
                </div>
                <div className="form-group">
                  <label>Numerical Field</label>
                  <input type="number" name="numericalField" onChange={handleJobConfigChange} />
                  <Tooltip title="Information about this field">
                    <InfoIcon />
                  </Tooltip>
                </div>
                <div className="form-group">
                  <label>File Upload Field</label>
                  <input type="file" name="fileUploadField" onChange={handleJobConfigChange} />
                  <Tooltip title="Information about this field">
                    <InfoIcon />
                  </Tooltip>
                </div>
                <div className="form-group">
                  <label>Email Field</label>
                  <input type="email" name="emailField" onChange={handleJobConfigChange} />
                  <Tooltip title="Information about this field">
                    <InfoIcon />
                  </Tooltip>
                </div>
                <div className="form-group">
                  <label>Tag Input Field</label>
                  <ReactTags
                    tags={basicTags}
                    handleDelete={handleDeleteBasicTag}
                    handleAddition={handleAdditionBasicTag}
                    inputFieldPosition="bottom"
                    autocomplete
                  />
                  <Tooltip title="Information about this field">
                    <InfoIcon />
                  </Tooltip>
                </div>
              </>
            )}
            {jobType === 'Advanced' && (
              <>
                <div className="form-group">
                  <label>Advanced Dropdown Field</label>
                  <select name="advancedDropdownField" onChange={handleJobConfigChange}>
                    <option value="">Select an option</option>
                    <option value="advancedOption1">Advanced Option 1</option>
                    <option value="advancedOption2">Advanced Option 2</option>
                  </select>
                  <Tooltip title="Information about this field">
                    <InfoIcon />
                  </Tooltip>
                </div>
                <div className="form-group">
                  <label>Advanced Radio Button Selection</label>
                  <label>
                    <input type="radio" name="advancedRadioSelection" value="advancedOption1" onChange={handleJobConfigChange} />
                    Advanced Option 1
                  </label>
                  <label>
                    <input type="radio" name="advancedRadioSelection" value="advancedOption2" onChange={handleJobConfigChange} />
                    Advanced Option 2
                  </label>
                  <Tooltip title="Information about this field">
                    <InfoIcon />
                  </Tooltip>
                </div>
                <div className="form-group">
                  <label>Advanced Checkbox Selection</label>
                  <label>
                    <input type="checkbox" name="advancedCheckboxSelection" onChange={handleJobConfigChange} />
                    Advanced Option
                  </label>
                  <Tooltip title="Information about this field">
                    <InfoIcon />
                  </Tooltip>
                </div>
                <div className="form-group">
                  <label>Advanced Text Field</label>
                  <input type="text" name="advancedTextField" onChange={handleJobConfigChange} />
                  <Tooltip title="Information about this field">
                    <InfoIcon />
                  </Tooltip>
                </div>
                <div className="form-group">
                  <label>Advanced Numerical Field</label>
                  <input type="number" name="advancedNumericalField" onChange={handleJobConfigChange} />
                  <Tooltip title="Information about this field">
                    <InfoIcon />
                  </Tooltip>
                </div>
                <div className="form-group">
                  <label>Advanced File Upload Field</label>
                  <input type="file" name="advancedFileUploadField" onChange={handleJobConfigChange} />
                  <Tooltip title="Information about this field">
                    <InfoIcon />
                  </Tooltip>
                </div>
                <div className="form-group">
                  <label>Advanced Email Field</label>
                  <input type="email" name="advancedEmailField" onChange={handleJobConfigChange} />
                  <Tooltip title="Information about this field">
                    <InfoIcon />
                  </Tooltip>
                </div>
                <div className="form-group">
                  <label>Advanced Tag Input Field</label>
                  <ReactTags
                    tags={advancedTags}
                    handleDelete={handleDeleteAdvancedTag}
                    handleAddition={handleAdditionAdvancedTag}
                    inputFieldPosition="bottom"
                    autocomplete
                  />
                  <Tooltip title="Information about this field">
                    <InfoIcon />
                  </Tooltip>
                </div>
              </>
            )}
          </>
        )}
        <button
          onClick={() => handleCreateJob(selectedProject.id, selectedJob)}
          disabled={!isCreateJobEnabled}
        >
          Create Job
        </button>
        <button onClick={closeAddJobPopup}>Cancel</button>
      </div>
    </div>
  );
};

export default JobForm;