// src/components/Terms.js

import React from 'react';
import './Terms.css'; // Create and import a CSS file for styling if necessary

const Terms = () => {
  return (
    <div className="termsContainer">
      <h1>Terms and Conditions</h1>
      <p>Last updated: [Date]</p>
      <h2>1. Introduction</h2>
      <p>Welcome to [Your Company]. By accessing or using our service, you agree to be bound by these terms and conditions.</p>
      <h2>2. User Accounts</h2>
      <p>When you create an account with us, you must provide us with information that is accurate, complete, and current at all times. Failure to do so constitutes a breach of the Terms, which may result in immediate termination of your account.</p>
      <h2>3. Third-Party Sign-In</h2>
      <p>If you sign in via any third-party applications such as Google, you automatically consent to these terms and conditions.</p>
      <h2>4. Use of the Service</h2>
      <p>You agree not to use the service for any purpose that is unlawful or prohibited by these Terms.</p>
      <h2>5. Termination</h2>
      <p>We may terminate or suspend access to our service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.</p>
      <h2>6. Changes</h2>
      <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. If a revision is material we will try to provide at least 30 days' notice prior to any new terms taking effect.</p>
      <h2>7. Contact Us</h2>
      <p>If you have any questions about these Terms, please contact us at [Your Contact Information].</p>
    </div>
  );
};

export default Terms;
