// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../hooks/useAuth'; // Ensure the path is correct

const ProtectedRoute = ({ children, redirectPath = '/teams', inverse = false }) => {
  const { user } = useAuth();
  const location = useLocation();

  if (!user && !inverse) {
    // User not logged in and the route is protected, redirect to login page
    return <Navigate to="/login" />;
  }

  if (user && inverse) {
    // User logged in and the route should not be accessible, redirect to the specified path
    return <Navigate to={redirectPath} state={{ from: location }} replace />;
  }

  return children; // Render the children components if the above conditions don't apply
};

export default ProtectedRoute;
