// src/index.js
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './styles/dark-mode.css'; // Import dark-mode.css
import './styles/global.css'; // Import global.css
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ProvideAuth } from './hooks/useAuth'; // Adjust this import based on your file structure

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ProvideAuth>
      <App />
    </ProvideAuth>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();