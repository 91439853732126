import React, { useState } from 'react';
import './Logo.css';
import { Link } from 'react-router-dom'; // Import Link for navigation


const Logo = () => {
  const [animation, setAnimation] = useState('');

  const clickAnimations = [
    'wobble'
  ];

  const getRandomAnimation = () => {
    // Generate a random index to choose from different click animations
    const randomIndex = Math.floor(Math.random() * clickAnimations.length);
    return clickAnimations[randomIndex];
  };

  const handleAnimationClick = () => {
    const randomClickAnimation = getRandomAnimation();
    setAnimation(randomClickAnimation);

    // Reset the animation class after it finishes
    setTimeout(() => {
      setAnimation('');
    }, 500); // Adjust the time based on the longest animation duration
  };

  return (
    <div className={`logo ${animation}`}>
        <Link to="/" onClick={handleAnimationClick}>DataJinx</Link>
    </div>
  );
};

export default Logo;
