// src/components/projects/ProjectForm.js
import React from 'react';
import { logChange } from '../../services/logService';
import { collection, addDoc, updateDoc, doc } from 'firebase/firestore';
import { db } from '../../config/firebase'; // Ensure db is imported

const allowProjectCreation = true; // Set this flag to true or false to allow or block project creation
const invokeLambdaFunction = true; // Set this flag to true or false to allow or block Lambda function invocation

const handleCreateProjectSubmit = async (e, newProject, user, projects, setProjects, setSelectedProject, setFilterStatus, closeCreateProjectPopup, setIsLoading, setIsRequestPending, setShowCreateFirstProject) => {
  e.preventDefault();
  const STATUS = { ACTIVE: 'active' }; // Add STATUS constant if it's not already imported
  const allowProjectCreation = true; // Keep the same condition

  if (!allowProjectCreation) {
    console.log("Project creation is currently disabled to stop lambda function in aws from being triggered constantly. \n***YU: Change/modify handleCreateProjectSubmit function in projects.js");
    return;
  }
  if (setIsRequestPending(true)) return;
  setIsLoading(true);

  // Check for duplicate project names before proceeding
  const projectExists = projects.some(project => project.name === newProject.name);
  if (projectExists) {
    console.log("A project with this name already exists.");
    setIsLoading(false);
    setIsRequestPending(false);
    return;
  }

  const createdAt = new Date().getTime();
  const newProjectData = {
    ...newProject,
    userId: user.uid,
    createdAt,
    status: STATUS.ACTIVE,
  };

  try {
    const docRef = await addDoc(collection(db, 'projects'), newProjectData);
    console.log("Project Status:", STATUS.ACTIVE.toUpperCase());
    newProjectData.id = docRef.id;

    setProjects((prevProjects) => [newProjectData, ...prevProjects]);
    setSelectedProject(newProjectData);
    setFilterStatus(STATUS.ACTIVE);

    await logChange(user.uid, `Created project ${newProjectData.name}`);

    if (invokeLambdaFunction) {
      // Call Lambda Function
      const callLambdaFunction = async () => {
        try {
          const baseUrl = 'https://thowvhggzi.execute-api.ap-southeast-2.amazonaws.com/prod/FirebaseAuthFunction';
          const queryParams = new URLSearchParams({
            user_uuid: user.uid,
            project_id: newProjectData.id,
            x: "TEXT_APPEARS"
            // Add more parameters here as needed
          });
          const response = await fetch(`${baseUrl}?${queryParams.toString()}`, {
            method: 'GET',
          });
          const data = await response.json();
          console.log('Response from Lambda:', data);
        } catch (error) {
          console.error('Error calling Lambda function:', error);
        }
      };

      await callLambdaFunction();
    }

    closeCreateProjectPopup();
  } catch (error) {
    console.error("Error adding project: ", error);
  } finally {
    setIsLoading(false);
    setTimeout(() => {
      setIsRequestPending(false);
    }, 1000);
  }
};


const handleEditProjectSubmit = async (e, editedProject, setIsRequestPending, setIsLoading, setProjects, user, logChange, closeEditProjectPopup, setSelectedProject, isRequestPending) => {
  e.preventDefault();
  if (isRequestPending) return;
  setIsRequestPending(true);
  setIsLoading(true);
  try {
    await updateDoc(doc(db, 'projects', editedProject.id), editedProject);

    setProjects((prevProjects) =>
      prevProjects.map((project) =>
        project.id === editedProject.id ? editedProject : project
      )
    );

    await logChange(user.uid, `Updated project ${editedProject.name}`);

    setSelectedProject(editedProject);
    closeEditProjectPopup();
  } catch (error) {
    console.error("Error updating project: ", error);
  } finally {
    setIsLoading(false);
    setIsRequestPending(false);
  }
};

const ProjectForm = ({ project, onSubmit, onChange, onClose, isCreate }) => {
  return (
    <div className={isCreate ? "create-project-popup" : "edit-project-popup"}>
      <div className={isCreate ? "create-project-popup-content" : "edit-project-popup-content"}>
        <h3>{isCreate ? "Create New Project" : "Edit Project"}</h3>
        <form onSubmit={onSubmit}>
          <input
            type="text"
            name="name"
            placeholder="Project Name"
            value={project.name}
            onChange={onChange}
            required
          />
          <textarea
            name="description"
            placeholder="Description"
            value={project.description}
            onChange={onChange}
            className="resizable-textarea"
          ></textarea>
          <input
            type="text"
            name="tags"
            placeholder="Tags"
            value={project.tags}
            onChange={onChange}
          />
          <input
            type="text"
            name="industry"
            placeholder="Industry"
            value={project.industry}
            onChange={onChange}
          />
          <input
            type="number"
            name="maxCredits"
            placeholder="Maximum Credits"
            value={project.maxCredits}
            onChange={onChange}
          />
          <input
            type="text"
            name="teamMembers"
            placeholder="Team Members"
            value={project.teamMembers}
            onChange={onChange}
          />
          <input
            type="number"
            name="priority"
            placeholder="Priority"
            min="1"
            max="10"
            value={project.priority}
            onChange={onChange}
          />
          <label>
            <input
              type="checkbox"
              name="starred"
              checked={project.starred}
              onChange={(e) =>
                onChange({
                  target: {
                    name: 'starred',
                    value: e.target.checked,
                  },
                })
              }
            />
            Star Project
          </label>
          {!isCreate && (
            <>
              <label>
                <input
                  type="radio"
                  name="status"
                  value="active"
                  checked={project.status === 'active'}
                  onChange={onChange}
                />
                Active
              </label>
              <label>
                <input
                  type="radio"
                  name="status"
                  value="backlogged"
                  checked={project.status === 'backlogged'}
                  onChange={onChange}
                />
                Backlogged
              </label>
            </>
          )}
          <button type="submit">{isCreate ? "Create Project" : "Update Project"}</button>
          <button type="button" onClick={onClose}>
            Cancel
          </button>
        </form>
      </div>
    </div>
  );
};

export default ProjectForm;
export { handleCreateProjectSubmit, handleEditProjectSubmit };
