// Homepage.js

import React, { useState, useEffect } from 'react';
import IntroSection from './IntroSection';
import FeaturesSection from './FeaturesSection';
import ProductDemo from './ProductDemo';
import './Homepage.css';

const Homepage = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  const toggleDarkMode = () => {
    document.body.classList.toggle('dark-mode');
    setIsDarkMode(!isDarkMode);
  };

  useEffect(() => {
    const text = "Welcome to DataJinx - Your Gateway to Intelligent Data Solutions";
    let index = 0;
    const intervalId = setInterval(() => {
      document.getElementById("scrolling-text").textContent = text.slice(0, index++);
      if (index > text.length) index = 0;
    }, 150);
    return () => clearInterval(intervalId);
  }, []);

  const blogPosts = [
    { title: "Blog Post 1", link: "#" },
    { title: "Blog Post 2", link: "#" },
    { title: "Blog Post 3", link: "#" }
  ];

  return (
    <div className="homepage">
      <header className="header">
        <div id="scrolling-text" className="scrolling-text"></div>
        <button onClick={toggleDarkMode}>
          {isDarkMode ? 'Light Mode' : 'Dark Mode'}
        </button>
      </header>
      <IntroSection />
      <FeaturesSection />
      <ProductDemo />
      <section className="value-section">
        <h2>The Value of DataJinx</h2>
        <p>Connect to your data, validate hypotheses, and generate dynamic insights with DataJinx.</p>
      </section>
      <section className="dynamic-testimonials">
        <h2>User Testimonials</h2>
        <div className="testimonial-slider">
          <div className="testimonial-item">"DataJinx transformed our data analysis!" - Customer 1</div>
          <div className="testimonial-item">"A game-changer for our business." - Customer 2</div>
        </div>
      </section>
      <section className="blog-section">
        <h2>Latest Blog Posts</h2>
        <ul>
          {blogPosts.map((post, index) => (
            <li key={index}><a href={post.link}>{post.title}</a></li>
          ))}
        </ul>
      </section>
      <footer className="footer">
        <p>Contact us at: info@datajinx.com</p>
        <p>Follow us on social media for updates and news.</p>
      </footer>
    </div>
  );
};

export default Homepage;
