import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';

const PaymentCompletion = () => {
  const { paymentIntentId } = useParams();
  const [paymentDetails, setPaymentDetails] = useState(null);

  useEffect(() => {
    const fetchPaymentDetails = async () => {
      try {
        const response = await axios.get(`https://us-central1-data-jinx.cloudfunctions.net/api/payment-intent/${paymentIntentId}`);
        setPaymentDetails(response.data);
      } catch (err) {
        console.error('Error fetching payment details:', err);
      }
    };

    fetchPaymentDetails();
  }, [paymentIntentId]);

  if (!paymentDetails) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <h2>Payment Status: {paymentDetails.status}</h2>
      <p>Amount: ${(paymentDetails.amount / 100).toFixed(2)}</p>
      <p>Currency: {paymentDetails.currency}</p>
      <p>Payment Method: {paymentDetails.payment_method}</p>
      <p>Created At: {new Date(paymentDetails.created * 1000).toLocaleString()}</p>
    </div>
  );
};

export default PaymentCompletion;
