// src/App.js

import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import UserLogin from './components/UserLogin';
import Signup from './components/Signup';
import Projects from './components/projects/Projects';
import UserDashboard from './components/UserDashboard';
import Header from './components/Header';
import Footer from './components/Footer';
import Homepage from './components/Homepage/Homepage';
import ProtectedRoute from './components/ProtectedRoute';
import ProvideAuth, { useAuth } from './hooks/useAuth';
import Terms from './components/Terms';
import Profile from './components/Profile';
import Teams from './components/Teams';
import Settings from './components/Settings';
import Notifications from './components/Notifications';
import Billing from './components/billing/Billing';
import PaymentCompletion from './components/billing/PaymentCompletion';
import Balance from './components/billing/Balance';
import { app } from './config/firebase';

function App() {
  useEffect(() => {
    const savedMode = localStorage.getItem('darkMode');
    if (savedMode && JSON.parse(savedMode)) {
      document.body.classList.add('dark-mode');
    }
  }, []);

  const toggleDarkMode = () => {
    document.body.classList.toggle('dark-mode');
    localStorage.setItem('darkMode', JSON.stringify(document.body.classList.contains('dark-mode')));
  };

  return (
    <ProvideAuth>
      <div>
        <button onClick={toggleDarkMode}>Switch to {document.body.classList.contains('dark-mode') ? 'Light' : 'Dark'} Mode</button>
        <AppRoutes />
      </div>
    </ProvideAuth>
  );
}

function AppRoutes() {
  const { loading } = useAuth();

  if (loading) {
    return (
      <div className="spinner-overlay">
        <div className="spinner"></div>
      </div>
    );
  }

  return (
    <Router>
      <div className="app">
        <Header className="header" />
        <div className="content">
          <Routes>
            <Route path="/login" element={<ProtectedRoute inverse={true}><UserLogin /></ProtectedRoute>} />
            <Route path="/signup" element={<ProtectedRoute inverse={true}><Signup /></ProtectedRoute>} />
            <Route path="/dashboard" element={<ProtectedRoute><UserDashboard /></ProtectedRoute>} />
            <Route path="/projects" element={<ProtectedRoute><Projects /></ProtectedRoute>} />
            <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
            <Route path="/teams" element={<ProtectedRoute><Teams /></ProtectedRoute>} />
            <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
            <Route path="/notifications" element={<ProtectedRoute><Notifications /></ProtectedRoute>} />
            <Route path="/homepage" element={<Homepage />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/billing" element={<ProtectedRoute><Billing /></ProtectedRoute>} />
            <Route path="/payment-completion/:paymentIntentId" element={<ProtectedRoute><PaymentCompletion /></ProtectedRoute>} />
            <Route path="/" element={<DefaultRoute />} />
            <Route path="/balance" element={<ProtectedRoute><Balance /></ProtectedRoute>} />
          </Routes>
        </div>
        <Footer className="footer" />
      </div>
    </Router>
  );
}

function DefaultRoute() {
  return (
    <div className="App">
      <Homepage />
    </div>
  );
}

export default App;
