// constants.js

export const predefinedJobs = [
    { id: 'job1', title: 'Generate Links to Crawl', mandatoryFields: ['Search Query (Data you would like to collect)', 'Select Search Engines to query', 'Minimum number of links to collect', ''], tasks: ['Getting top n search results from search engine', 'Visiting each link', 'Collecting data', 'Consolidating data', 'Preparing RDS session for connection'] },
    { id: 'job2', title: 'Start Data Collection', mandatoryFields: ['config2'], tasks: ['Creating AWS Elastic Beanstalk Instance', 'Provisioning EC2 Resources', 'Rotating IP Addresses', 'Task 9'] },
    { id: 'job3', title: 'Access Dirty Data', mandatoryFields: ['config3'], tasks: ['task2'] },
    { id: 'job4', title: 'Clean and Consolidate Collected Data', mandatoryFields: ['config4'], tasks: ['task200'] },
    { id: 'job5', title: 'Access Cleaned Data', mandatoryFields: ['config5'], tasks: ['task2000'] },
    { id: 'job6', title: 'Create Insights Report from Prepared Data', mandatoryFields: ['config6'], tasks: ['Creating Visualisation of sourced data'] },
    { id: 'job7', title: 'Train AI Model on Prepared Data', mandatoryFields: ['config7'], tasks: ['RNN', 'Word Embeddings for LLM', 'Establishing Connection between RNN and LLM'] },
    { id: 'job8', title: 'Upload/Connect to Own Data', mandatoryFields: ['Upload XLSX, XLS, CSV', 'PDF, DOCX, DOC, TXT', 'JSON', 'Online DBMS [Snowflake, AWS RDS, GCP, MS...'], tasks: ['Ingesting/Connecting to Source Data', 'Analysing Structure of Data using LLM', 'Data Prepared for additional jobs with Jinx'] }
  ];
  
export const STATUS = {
    ACTIVE: 'active',
    PAUSED: 'paused',
    DELETED: 'deleted',
  };