// src/components/projects/ProjectList.js
import React from 'react';
import Balance from '../billing/Balance';
import { STATUS } from './constants';
import './Projects.css';

const ProjectList = ({
  projects,
  selectedProject,
  setSelectedProject,
  openCreateProjectPopup,
  searchTerm,
  setSearchTerm,
  isSidebarOpen,
  setIsSidebarOpen
}) => {
  // Filter out projects with duplicate IDs
  const uniqueProjects = projects.filter((project, index, self) =>
    index === self.findIndex((p) => p.id === project.id)
  );

  const filteredProjects = searchTerm
    ? uniqueProjects.filter((project) =>
      (project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.tags.toLowerCase().includes(searchTerm.toLowerCase())) &&
      project.status !== STATUS.DELETED
    )
    : uniqueProjects.filter((project) => project.status !== STATUS.DELETED);

  const isDarkMode = document.body.classList.contains('dark-mode');

  return (
    <aside className={`project-list ${isSidebarOpen ? '' : 'collapsed'} ${isDarkMode ? 'dark-mode' : ''}`}>
      <button className="sidebar-toggle" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
        {isSidebarOpen ? '◀' : '▶'}
      </button>
      {isSidebarOpen && (
        <>
          <Balance />
          <button className="create-project-btn" onClick={openCreateProjectPopup}>
            Create New Project
          </button>
          <div className="divider"></div>
          <div className="search-container">
            <input
              type="text"
              placeholder="Search Projects..."
              onChange={(e) => setSearchTerm(e.target.value)}
              className="dark-mode-text"
            />
          </div>
          {filteredProjects.map((project) => (
            <div key={project.id} className="project-item-container">
              <button
                onClick={() => {
                  setSelectedProject(project);
                  setSearchTerm('');
                }}
                className={`project-item ${selectedProject?.id === project.id ? 'active' : ''} dark-mode-text`}
              >
                {project.name}
              </button>
            </div>
          ))}
          <div className="divider"></div>
        </>
      )}
    </aside>
  );
};

export default ProjectList;
