import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import { STRIPE_PUBLISHABLE_KEY } from '../../config/stripe';
import { useAuth } from '../../hooks/useAuth';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../config/firebase';

const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);

const PaymentForm = ({ amount, storedCards }) => {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = useAuth();
  const [error, setError] = useState(null);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [selectedCard, setSelectedCard] = useState('');
  const [customerId, setCustomerId] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchCustomerId = async () => {
      const billingRef = doc(db, 'users', user.uid, 'billing', 'default');
      const customerDoc = await getDoc(billingRef);
      if (customerDoc.exists()) {
        setCustomerId(customerDoc.data().customerId);
      }
    };

    if (user) {
      fetchCustomerId();
    }
  }, [user]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!stripe || !elements) return;

    let paymentMethodId;
    if (selectedCard) {
      const card = storedCards.find(card => card.id === selectedCard);
      if (!card || !card.paymentMethodId) {
        setError({ message: 'Selected card does not have a valid payment method ID.' });
        setLoading(false);
        return;
      }
      paymentMethodId = card.paymentMethodId;
    } else {
      const cardElement = elements.getElement(CardElement);
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (error) {
        setError(error);
        setLoading(false);
        return;
      } else {
        paymentMethodId = paymentMethod.id;
        setPaymentMethod(paymentMethod);
      }
    }

    console.log("Sending request to server:", {
      paymentMethodId,
      customerId,
      userId: user.uid,
      amount: amount * 100,
      userDetails: {
        email: user.email,
        name: user.displayName,
      },
    });

    try {
      const response = await axios.post('https://us-central1-data-jinx.cloudfunctions.net/api/create-payment-intent', {
        paymentMethodId,
        customerId,
        userId: user.uid,
        amount: amount * 100, // Convert dollars to cents
        userDetails: {
          email: user.email,
          name: user.displayName,
        },
      });
      console.log("Response from server:", response.data);
      // Handle response from your server
    } catch (err) {
      console.error("Error from server:", err.response ? err.response.data : err.message);
      setError(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {loading && (
        <div className="spinner-overlay">
          <div className="spinner"></div>
        </div>
      )}
      <h3>Select a Stored Card</h3>
      {storedCards.length > 0 ? (
        <select onChange={(e) => setSelectedCard(e.target.value)}>
          <option value="">Use a new card</option>
          {storedCards.map((card) => (
            <option key={card.id} value={card.id}>
              {card.cardNumber} (exp: {card.expiryDate})
            </option>
          ))}
        </select>
      ) : (
        <p>No stored cards found. Please add a card.</p>
      )}
      {!selectedCard && <CardElement />}
      <button type="submit" disabled={!stripe || loading}>
        {loading ? 'Processing...' : `Pay $${amount}`}
      </button>
      {error && <div>{error.message}</div>}
    </form>
  );
};

const Payment = ({ amount, storedCards }) => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentForm amount={amount} storedCards={storedCards} />
    </Elements>
  );
};

export default Payment;
